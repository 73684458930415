import { DataGrid } from "devextreme-react";
import appSettings from "../../../appsettings";
import CustomStore from "devextreme/data/custom_store"
import DataSource from 'devextreme/data/data_source';
import {
    Column,
    FilterRow,
    Scrolling,
    Editing,
    Texts,
    Lookup,
    Selection,
} from "devextreme-react/data-grid";
import React, { useCallback, useState, useEffect, useRef, useMemo } from "react";
import msalFetch from "../../../api/MsalFetch.js";
import toast from "react-hot-toast";

const GridEditEolConnectorColumn = ({
    selectedConnectorId,
    //tableId
}) => {
    const [columns, setColumns] = useState();
    const [info, setInfo] = useState();
    const refConnectorColumn = useRef();
    const [tableId, setTableId] = useState();

    const getConnectorColumns = useCallback(async () => {
        if (!selectedConnectorId)
            return;
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/exactonline/index-control-exact-online-column/${selectedConnectorId}`);
            const data = await response.json();
            //setLoading(false);
            return data;

        } catch (error) {
            //setLoading(false);
        }
    }, [selectedConnectorId]);

    const deleteConnectorColumn = useCallback(async (id) => {
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/exactonline/delete-control-exact-online-column/${id}`,
                {
                    method: "DELETE",
                    headers: { "Content-type": "application/json" }
                }
            );
        } catch (error) {
            toast.error("Er is iets misgegaan");
            throw error;
        }
    }, []);

    const insertConnectorColumn = useCallback(async (values) => {
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/exactonline/create-control-exact-online-column`,
                {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify({ ...values, ControlExactOnlineId: selectedConnectorId }),
                }
            );
        } catch (error) {
            toast.error("Er is iets misgegaan");
            throw error;
        }
    }, [selectedConnectorId]);

    const updateConnectorColumn = useCallback(async (id, values) => {
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/exactonline/update-control-exact-online-column/${id}`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(values),
                }
            );
        } catch (error) {
            toast.error("Er is iets misgegaan");
            throw error;
        }
    }, []);

    const dataSourceConnectorColumn = useMemo(() => new DataSource({
        store: new CustomStore({
            key: 'id',
            load: getConnectorColumns,
            update: updateConnectorColumn,
            remove: deleteConnectorColumn,
            insert: insertConnectorColumn
        })
    }), [getConnectorColumns, updateConnectorColumn, deleteConnectorColumn, insertConnectorColumn]);

    useEffect(() => {
        const innerUseEffect = async () => {
            try {
                const res = await msalFetch(null, `${appSettings.api.endpoint}/api/exactonline/read/${selectedConnectorId}`);
                const json = await res.json();
                setTableId(json.tableId);
                setInfo(`(${json.name} - ${json.entity})`);
            } catch (error) {
                toast.error("Er is iets misgegaan");
                throw error;
            }
        };
        innerUseEffect();
    }, [selectedConnectorId])

    useEffect(() => {
        const innerUseEffect = async () => {
            try {
                const res = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/dataset/${tableId}/column`);
                const json = await res.json();
                const data = [...json.map(item => { return { ...item, displayName: `${item.name} (${item.columnType.name})` } })];
                debugger;
                setColumns(data.sort((a, b) => a.displayName.localeCompare(b.displayName)));
            } catch (error) {
                toast.error("Er is iets misgegaan");
                throw error;
            }
        };
        innerUseEffect();
    }, [tableId])

    const onInitNewRow = useCallback((e) => {
        e.data.isExactOnlineKey = false;
    }, []);
    if (!columns)
        return null;
    return (<DataGrid
        title={info}
        dataSource={dataSourceConnectorColumn}
        rowAlternationEnabled={true}
        showColumnLines={false}
        hoverStateEnabled={true}
        showBorders
        showRowLines={true}
        focusedRowEnabled={true}
        className="sorting-disabled"
        keyExpr="id"
        ref={refConnectorColumn}
        onInitNewRow={onInitNewRow}
    >
        <FilterRow visible={true} />
        <Scrolling mode="virtual" />
        <Column
            caption="Kolomnaam"
            dataField="columnId"
            dataType="string"
        >
            <Lookup dataSource={columns} displayExpr="displayName" valueExpr="id" />
        </Column>
        <Column
            caption="Kolomtype"
            dataField="columnType"
            dataType="string"
        />
        <Column
            caption="ExactOnlineproperty"
            dataField="exactOnlinePropertyName"
            dataType="string"
            allowUpdating={false}
        />
        <Column
            caption="Key"
            dataField="isExactOnlineKey"
            dataType="boolean"
        />
        <Column
            type="buttons"
            caption={"Acties"}>
        </Column>
        <Editing
            mode="row"
            confirmDelete={true}
            allowDeleting={true}
            allowUpdating={true}
            allowAdding={true}
            useIcons={true}
        >
            <Texts
                confirmDeleteTitle="Item verwijderen"
                confirmDeleteMessage="Weet u zeker dat u dit item wilt verwijderen?"
            />
        </Editing>
        <Selection mode="none" />
    </DataGrid>)
}
export default GridEditEolConnectorColumn;
