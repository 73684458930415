import React, {
    memo,
    useCallback,
    useRef,
} from 'react';
import { useHistory } from "react-router-dom";
import { TreeView } from 'devextreme-react/tree-view';
import ContextMenu from 'devextreme-react/context-menu';

import './menu.scss';

const contextMenuItems = [
    {
        id: 'expand',
        text: 'Alles uitklappen',
        visible: true,
    },
    {
        id: 'collapse',
        text: 'Alles dichtklappen',
        visible: true,
    },
];

export const Menu = ({
    children,
    items,
}) => {
    const { push } = useHistory();
    const treeViewRef = useRef(null);

    const selectedItemChanged = useCallback((item) => {
        if (item.itemData.path) {
            push(item.itemData.path);
        }
    }, [push]);


    const treeViewItemContextMenu = useCallback((e) => {
    }, []);

    const contextMenuItemClick = useCallback((e) => {
        const { id } = e.itemData;
        if (id === 'expand') {
            return treeViewRef.current.instance().expandAll();
        }
        if (id === 'collapse') {
            return treeViewRef.current.instance().collapseAll();
        }
        return;
    }, []);

    return (
        <div className='dx-swatch-additional side-navigation-menu'>
            {children}
            <div className='bloom-menu menu-container theme-dependent'>
                <TreeView
                    id="layout-treeview"
                    ref={treeViewRef}
                    items={items}
                    keyExpr="name"
                    focusStateEnabled={false}
                    noDataText="Menuitem niet gevonden"
                    expandEvent="click"
                    onItemClick={selectedItemChanged}
                    width="100%"
                    searchEnabled={true}
                    searchMode="contains"
                    selectByClick={true}
                    selectionMode="single"
                    onItemContextMenu={treeViewItemContextMenu}
                />
                <ContextMenu
                    dataSource={contextMenuItems}
                    target={`#layout-treeview .dx-treeview-item`}
                    onItemClick={contextMenuItemClick}
                />
            </div>
        </div>
    );
};

// use memo to prevent it from collapsing on route changes
export default memo(Menu);
