import React, { useState } from "react";
import Loading from "../../loading/Loading";
import BloomFooter from "../../footer/BloomFooter";
import { Container, Col, Row } from "react-bootstrap";
import GridEditEolConnector from "./GridEditEolConnector.js";

const IndexEolConnectors = () => {
    const [loading, setLoading] = useState(false);
    const [selectedConnectorId, setSelectedConnectorId] = useState();

    return (
        <main className="bl-content">
            <header>
                <h2>ExactOnlineconnectoren</h2>
            </header>
            {loading && <Loading />}
            {!loading && (
                <Container fluid
                    className={"bl-detailview-content"}>
                    <section >
                        <Row className="bl-detail-row">
                            <Col
                                lg={12}
                                className={`bl-col-padding`}
                            >
                                <section className="bl-detail-column bl-card">
                                    <h3 className="bl-set-title" >
                                        Connector
                                    </h3>
                                    <section className="propertyWrapper" >
                                        <GridEditEolConnector
                                            selectedConnectorId={selectedConnectorId}
                                            setSelectedConnectorId={setSelectedConnectorId}
                                        />
                                    </section>
                                </section>
                            </Col>
                        </Row>
                    </section>
                </Container>
            )
            }

            <BloomFooter />

        </main >
    );
}
export default IndexEolConnectors;