import { DataGrid } from "devextreme-react";
import appSettings from "../../../appsettings";
import CustomStore from "devextreme/data/custom_store"
import DataSource from 'devextreme/data/data_source';
import {
    Column,
    FilterRow,
    Scrolling,
    Editing,
    Texts,
    Lookup
} from "devextreme-react/data-grid";
import React, { useCallback, useState, useEffect, useMemo } from "react";
import msalFetch from "../../../api/MsalFetch.js";
import toast from "react-hot-toast";

const GridControlExactOnlineRelation = (
    {
        eolConnectorId
    }
) => {
    const [controlExacts, setControlExacts] = useState();
    const [columns, setColumns] = useState();

    const getConnectorRelations = useCallback(async () => {
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/exactonline/index-control-exact-online-relation/${eolConnectorId}`);
            const data = await response.json();
            //setLoading(false);
            return data;

        } catch (error) {
            //setLoading(false);
        }
    }, [eolConnectorId]);

    const getControlExacts = useCallback(async () => {
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/exactonline/index-control-exact-online/`);
            const data = await response.json();
            setControlExacts(data);

        } catch (error) {
            //setLoading(false);
        }
    }, []);

    const getColumns = useCallback(async () => {
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/table/get-columns`);
            const data = await response.json();
            const sort = (a, b) => {
                if (a.tableName.toLowerCase() < b.tableName.toLowerCase()) return -1;  // 'a' should come before 'b'
                if (a.tableName.toLowerCase() > b.tableName.toLowerCase()) return 1;   // 'b' should come before 'a'

                // If the 'name' fields are equal, compare the 'city' fields
                if (a.columnName.toLowerCase() < b.columnName.toLowerCase()) return -1;  // 'a' should come before 'b'
                if (a.columnName.toLowerCase() > b.columnName.toLowerCase()) return 1;   // 'b' should come before 'a'

                // If both fields are equal, return 0 (no change)
                return 0;
            };
            setColumns(data.sort(sort).map(item => { return { ...item, displayName: `${item.tableName} - ${item.columnName}` } }));

        } catch (error) {
            //setLoading(false);
        }
    }, []);

    useEffect(() => {
        const innerUseEffect = async () => {
            await getConnectorRelations();
            await getControlExacts();
            await getColumns();
        };
        innerUseEffect();
    }, [getConnectorRelations, getControlExacts, getColumns]);

    const updateConnectorRelation = useCallback(async (id, values) => {
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/exactonline/update-control-exact-online-relation/${id}`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(values),
                }
            );
        } catch (error) {
            toast.error("Er is iets misgegaan");
            throw error;
        }
    }, []);

    const insertConnectorRelation = useCallback(async (values) => {
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/exactonline/insert-control-exact-online-relation/${eolConnectorId}`,
                {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(values),
                }
            );
        } catch (error) {
            toast.error("Er is iets misgegaan");
            throw error;
        }
    }, [eolConnectorId]);

    const deleteConnectorRelation = useCallback(async (id) => {

        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/exactonline/delete-control-exact-online-relation/${id}`,
                {
                    method: "DELETE",
                    headers: { "Content-type": "application/json" }
                }
            );
        } catch (error) {
            toast.error("Er is iets misgegaan");
            throw error;
        }
    }, []);

    const dataSourceConnector = useMemo(() => new DataSource({
        store: new CustomStore({
            key: 'id',
            load: getConnectorRelations,
            update: updateConnectorRelation,
            remove: deleteConnectorRelation,
            insert: insertConnectorRelation
        })
    }), [getConnectorRelations, updateConnectorRelation, deleteConnectorRelation, insertConnectorRelation]);

    const handleRelationRowClick = useCallback(async (e) => {

    }, [])

    return <DataGrid
        dataSource={dataSourceConnector}
        rowAlternationEnabled={true}
        showColumnLines={false}
        hoverStateEnabled={true}
        showBorders
        showRowLines={true}
        focusedRowEnabled={true}
        className="sorting-disabled"
        keyExpr="id"
        onCellClick={handleRelationRowClick}
        allowUpdating={true}
    >
        <FilterRow visible={true} />
        <Scrolling mode="virtual" />

        <Column
            caption="EOL-property"
            dataField="exactOnlinePropertyName"
            dataType="string"
        />
        <Column
            caption="CEO"
            dataField="controlExactOnlineIdRelation"
            dataType="string"
        >
            <Lookup dataSource={controlExacts} displayExpr="name" valueExpr="id" />
        </Column>
        <Column
            caption="Kolom"
            dataField="columnId"
            dataType="string"
        >
            <Lookup dataSource={columns} displayExpr="displayName" valueExpr="id" />
        </Column>
        <Column
            caption="Keyolom"
            dataField="keyColumnId"
            dataType="string"
        >
            <Lookup dataSource={columns} displayExpr="displayName" valueExpr="id" />
        </Column>

        <Editing
            mode="row"
            confirmDelete={true}
            allowDeleting={true}
            allowUpdating={true}
            allowAdding={true}
            useIcons={true}
        >
            <Texts
                confirmDeleteTitle="Item verwijderen"
                confirmDeleteMessage="Weet u zeker dat u dit item wilt verwijderen?"
            />
        </Editing>
    </DataGrid>;

}

export default GridControlExactOnlineRelation