import React, { memo, useCallback, useMemo } from "react";
import { DateBox, Button as DateBoxButton } from "devextreme-react/date-box";
import moment from "moment";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useGlobalContext } from "../../context/context";
import FieldLabel from "../detailview/FieldLabel";

const DATE = ({
    type = 'date',
    label,
    displayFormat,
    value,
    readonly,
    mandatory,
    columnId,
    registerLoaded,
    onControlUpdate,
    memoField,
    getColumnByColumnId,
    validateAgainstColumnId,
}) => {
    const onInitialized = useCallback(() => registerLoaded(columnId), [registerLoaded, columnId]);
    const minDateColumn = getColumnByColumnId(validateAgainstColumnId === 0 ? null : validateAgainstColumnId);
    const minDate = minDateColumn?.value;
    const minDateLabel = minDateColumn?.alias;
    const dateOutOfRangeMessage = minDate ? `Datum moet komen na ${moment.parseZone(minDate).utc(true).format("DD-MM-YYYY")} (${minDateLabel})` : null;

    const handleDateChange = useCallback((e) => {
        if (!e.value) {
            return onControlUpdate(columnId, null);
        }
        // parse it as utc, so the hours will not be shifted by moment
        const newValue = moment.parseZone(e.value).utc(true);
        if (type === 'date') {
            newValue.hour(0).minute(0).second(0).millisecond(0);
        }
        const newValueIso = newValue.format('YYYY-MM-DDTHH:mm:ss');
        // console.log("e.value", e.value, "newValueIso", newValueIso, "typeof value", typeof e.value);
        onControlUpdate(columnId, newValueIso);
        //onControlUpdate(columnId, newValue);
    }, [onControlUpdate, columnId, type]);

    const { isMobile } = useGlobalContext();
    const todayButton = {
        icon: 'clock',
        stylingMode: "text",

        onClick: () => {
            const newDate = new Date();
            handleDateChange({ value: newDate });
        },
    };
    const prevDateButton = useMemo(
        () => ({
            icon: 'spinprev',
            stylingMode: 'text',
            onClick: () => {

                const newDate = moment(value).subtract(1, 'days');
                handleDateChange({ value: newDate });
            },
        }),
        [value, handleDateChange],
    );
    const nextDateButton = useMemo(
        () => ({
            icon: 'spinnext',
            stylingMode: 'text',
            onClick: () => {
                const newDate = moment(value).add(1, 'days');
                handleDateChange({ value: newDate });
            },
        }),
        [value, handleDateChange],
    );

    return (
        <Form.Group>
            <Row>
                <Col md="4">
                    <FieldLabel
                        value={value}
                        label={label}
                        columnId={columnId}
                        memoField={memoField}
                        mandatory={mandatory}
                    />
                </Col>
                <Col md="8">
                    <DateBox
                        calendarOptions={{
                            showWeekNumbers: true,
                            ...(minDate ? { min: minDate } : {}),
                        }}
                        {...(minDate ? {
                            min: minDate,
                            dateOutOfRangeMessage,
                        } : {})}
                        applyValueMode={type === "datetime" ? "useButtons" : "instantly"}
                        type={type}
                        todayButtonText="Vandaag"
                        readOnly={readonly}
                        mandatory={mandatory}
                        stylingMode={readonly ? "filled" : "outlined"}
                        value={value}
                        showAnalogClock={true}
                        // valueChangeEvent="input"
                        showClearButton={true}
                        displayFormat={displayFormat ? displayFormat : "dd-MM-yyyy"}
                        onValueChanged={handleDateChange}
                        pickerType={isMobile ? "rollers" : "calendar"}
                        interval={30}
                        onInitialized={onInitialized}
                        acceptCustomValue={true}
                        // openOnFieldClick={true}
                        useMaskBehavior={true}
                        showTodayButton={true}
                        showDropDownButton={true}
                    >

                        <DateBoxButton
                            name="vandaag"
                            location="before"
                            options={todayButton}
                            tabIndex="-1"
                        />
                        {value &&
                            <DateBoxButton
                                name="prevDate"
                                location="before"
                                options={prevDateButton}
                            />}
                        {value &&
                            <DateBoxButton
                                name="nextDate"
                                location="after"
                                options={nextDateButton}
                            />
                        }
                        <DateBoxButton name="clear" location="after" tabIndex="-1" />
                        <DateBoxButton name="dropDown" />
                    </DateBox>
                </Col>
            </Row>
        </Form.Group>
    );
};

export default memo(DATE);
