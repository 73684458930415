import msalFetch from "../../../api/MsalFetch.js";
import appSettings from "../../../appsettings.js";
import react, { useState, useEffect, useCallback, useRef } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { TextBox, Button, TextArea } from "devextreme-react";
import toast from "react-hot-toast";



const EolSettings = () => {
    const [exactOnlineClientId, setExactOnlineClientId] = useState();
    const [exactOnlineClientSecret, setExactOnlineClientSecret] = useState();
    const [exactOnlineDivision, setExactOnlineDivision] = useState();
    const [exactOnlineRedirectUrl, setExactOnlineRedirectUrl] = useState();
    const [exactOnlineRefreshToken, setExactOnlineRefreshToken] = useState();
    //const [exactOnlineRefreshTokenExpireDate, setExactOnlineRefreshTokenExpireDate] = useState();
    const [exactOnlineAccessToken, setExactOnlineAccessToken] = useState();

    useEffect(() => {
        const innerUseEffect = async () => {
            const data = await msalFetch(null, `${appSettings.api.endpoint}/api/settings/get-all`);
            const json = await data.json();
            setExactOnlineClientId(json.find(item => item.name === 'ExactOnlineClientId')?.value ?? '');
            setExactOnlineClientSecret(json.find(item => item.name === 'ExactOnlineClientSecret')?.value ?? '');
            setExactOnlineDivision(json.find(item => item.name === 'ExactOnlineDivision')?.value ?? '');
            setExactOnlineRedirectUrl(json.find(item => item.name === 'ExactOnlineRedirectUrl')?.value ?? '');
            setExactOnlineRefreshToken(json.find(item => item.name === 'ExactOnlineRefreshToken')?.value ?? '');
            //setExactOnlineRefreshTokenExpireDate(json.find(item => item.name === 'ExactOnlineRefreshTokenExpireDate')?.value ?? '');
            setExactOnlineAccessToken(json.find(item => item.name === 'ExactOnlineAccessToken')?.value ?? '')
        };
        innerUseEffect();
    }, [])

    const onChange = useCallback((name) => (e) => {
        switch (name) {
            case 'ExactOnlineClientId':
                setExactOnlineClientId(e.value);
                break;
            case 'ExactOnlineClientSecret':
                setExactOnlineClientSecret(e.value);
                break;
            case 'ExactOnlineDivision':
                setExactOnlineDivision(e.value);
                break;
            case 'ExactOnlineRedirectUrl':
                setExactOnlineRedirectUrl(e.value);
                break;
            case 'ExactOnlineRefreshToken':
                setExactOnlineRefreshToken(e.value);
                break;
            /*case 'ExactOnlineRefreshTokenExpireDate':
                setExactOnlineRefreshTokenExpireDate(e.value);
                break;*/
            case 'ExactOnlineAccessToken':
                setExactOnlineAccessToken(e.value);
                break;
            default:

        }
    }, []);

    const onSubmit = useCallback(async () => {
        const url = `${appSettings.api.endpoint}/api/settings/update-settings`;
        try {
            const res = await msalFetch(null, url, {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify({
                    ExactOnlineClientId: exactOnlineClientId,
                    ExactOnlineClientSecret: exactOnlineClientSecret,
                    ExactOnlineDivision: exactOnlineDivision,
                    ExactOnlineRedirectUrl: exactOnlineRedirectUrl,
                    ExactOnlineRefreshToken: exactOnlineRefreshToken,
                    //ExactOnlineRefreshTokenExpireDate: exactOnlineRefreshTokenExpireDate,
                    ExactOnlineAccessToken: exactOnlineAccessToken,
                }),
            });
            //const data = await res.json();
            if (res.ok) {
                toast.success('Instellingen zijn opgeslagen');
            }
            else {
                toast.error('Fout opgetreden bij het opslaan van de instellingen');
            }
        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            throw error;
        }
    }, [exactOnlineClientId, exactOnlineClientSecret, exactOnlineDivision, exactOnlineRedirectUrl, exactOnlineRefreshToken, exactOnlineAccessToken]);


    const registerApp = useCallback(async (e) => {
        const url = `https://apps.exactonline.com/nl/nl-NL/V2/Account/ExternalLogin?returnUrl=https%3A%2f%2fapps.exactonline.com%2fnl%2fnl-NL%2fV2%2fManage`;

        const popup = window.open(url, 'ExactOnlinePopup', 'width=800,height=600,scrollbars=yes,resizable=yes');
        // Optional: Check if the popup was blocked (e.g., by a browser popup blocker)
        if (!popup) {
            alert("Popup blocked! Please allow popups for this site.");
        }
    }, []);


    const onRefreshRefreshTokenClick = useCallback(async (e) => {
        const url = `https://start.exactonline.nl/api/oauth2/auth?client_id=${exactOnlineClientId}&redirect_uri=${exactOnlineRedirectUrl}&response_type=code&force_login=0`;
        const popup = window.open(url, 'ExactOnlinePopup', 'width=1024,height=768,scrollbars=yes,resizable=yes');
        // Optional: Check if the popup was blocked (e.g., by a browser popup blocker)
        if (!popup) {
            alert("Popup blocked! Please allow popups for this site.");
        }
    }, [exactOnlineClientId, exactOnlineRedirectUrl]);

    const onTestConnectionClick = useCallback(async () => {
        const url = `${appSettings.api.endpoint}/api/exactOnline/validate-connection`;
        try {
            const res = await msalFetch(null, url);
            const json = await res.json();
            console.log("json", json);
            if (res.ok) {
                toast.success(json.response);
            }
            else {
                toast.error('Fout opgetreden bij het valideren van de connectie');
            }
        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            throw error;
        }
    }, []);

    return <>
        <Col>
            <section >
                <h3 className="bl-set-title" >
                    Exact Online
                </h3>
                <br></br>
                <Form.Group>
                    <Row className="bl-detail-row gx-10">
                        <Col lg="2">
                            Registreer een app
                        </Col>
                        <Col lg="2">
                            <Button icon="fas fa-arrow-up-right-from-square" onClick={registerApp} hint='Registreer een app' />

                        </Col>
                        <Col lg="2">
                            Ophalen refreshtoken
                        </Col>
                        <Col lg="2">
                            <Button icon="fas fa-refresh" onClick={onRefreshRefreshTokenClick} />
                        </Col>
                        <Col lg="2">
                            Test connectie
                        </Col>
                        <Col lg="2">
                            <Button icon="fas fa-microscope" onClick={onTestConnectionClick} />
                        </Col>
                    </Row>
                    <br></br>
                    <Row className="bl-detail-row gx-10">
                        <Col lg="2">
                            ClientId
                        </Col>
                        <Col lg="8">
                            <TextBox
                                value={exactOnlineClientId}
                                onValueChanged={onChange('ExactOnlineClientId')}
                                showClearButton={false}
                                valueChangeEvent="input"
                            />
                        </Col>
                    </Row><Row>
                        <Col lg="2">
                            ClientSecret
                        </Col>
                        <Col lg="8">
                            <TextBox
                                value={exactOnlineClientSecret}
                                onValueChanged={onChange('ExactOnlineClientSecret')}
                                showClearButton={false}
                                valueChangeEvent="input"
                            />
                        </Col>
                    </Row>

                    <Row className="bl-detail-row gx-10">
                        <Col lg="2">
                            Redirect URL
                        </Col>
                        <Col lg="8">
                            <TextBox
                                value={exactOnlineRedirectUrl}
                                onValueChanged={onChange('ExactOnlineRedirectUrl')}
                                showClearButton={false}
                                valueChangeEvent="input"
                                readOnly
                                disabled
                            />
                        </Col>
                        <Col lg="2">
                            <Button icon="fas fa-clone" hint="Kopieer URL" onClick={() => {
                                navigator.clipboard.writeText(exactOnlineRedirectUrl);
                                toast.success("Redirect URL gekopieerd");
                            }}
                            />
                        </Col>
                    </Row>
                    <Row className="bl-detail-row gx-10">
                        <Col lg="2">
                            RefreshToken
                        </Col>


                        <Col lg="8">
                            <TextBox
                                value={exactOnlineRefreshToken}
                                onValueChanged={onChange('ExactOnlineRefreshToken')}
                                showClearButton={false}
                                valueChangeEvent="input"
                                disabled

                            />
                        </Col>
                        <Col lg="2">
                            <Button icon="fas fa-clone" hint="Kopieer refreshtoken" onClick={() => {
                                navigator.clipboard.writeText(exactOnlineRefreshToken);
                                toast.success("Refreshtoken gekopieerd");
                            }} />
                        </Col>
                    </Row>
                    <Row className="bl-detail-row gx-10">
                        <Col lg="2">
                            AccessToken
                        </Col>

                        <Col lg="8">
                            <TextBox
                                value={exactOnlineAccessToken}
                                onValueChanged={onChange('ExactOnlineAccessToken')}
                                showClearButton={false}
                                valueChangeEvent="input"
                                disabled

                            />
                        </Col>
                        <Col lg="2">
                            <Button icon="fas fa-clone" hint="Kopieer accesstoken" onClick={() => {
                                navigator.clipboard.writeText(exactOnlineAccessToken);
                                toast.success("Accesstoken gekopieerd");
                            }} />
                        </Col>
                    </Row>
                    {/* <Row className="bl-detail-row gx-10">
                        <Col lg="4">
                            Ophalen refreshtoken
                        </Col>
                        <Col lg="8">
                            <Button icon="fas fa-refresh" onClick={onRefreshRefreshTokenClick} />
                        </Col>
                    </Row>
                    <Row className="bl-detail-row gx-10">
                        <Col lg="4">
                            Test connectie
                        </Col>
                        <Col lg="8">
                            <Button icon="fas fa-microscope" onClick={onTestConnectionClick} />
                        </Col>
                    </Row> */}
                </Form.Group>
                <Button text='Opslaan' onClick={onSubmit} />
            </section>
        </Col>
    </>
}

export default EolSettings;