import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState, useCallback } from "react";
import msalFetch from "../../../../../api/MsalFetch.js";
import appSettings from "../../../../../appsettings";

import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import {
    CheckBox,
    DateBox,
    NumberBox,
    Resizable,
    SelectBox,
    TextArea,
} from "devextreme-react";
import Loading from "../../../../loading/Loading";

const ExactOnline = (
    {
        tableId,
        fieldValue,
        setFieldValue,
        checkChanges,
        isCreate,
    }
) => {
    console.log("ExactOnline", tableId, fieldValue);
    const [controlExactOnlines, setControlExactOnlines] = useState();

    const getControlExactOnlines = useCallback(async () => {
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/exactOnline/index-control-exact-online`);
            const data = await response.json();
            console.log("getControlExactOnlines", data.filter(item => item.tableId === tableId), data);
            setControlExactOnlines(data.filter(item => item.tableId === tableId));
        } catch (error) {
            throw "Get User table Id error";
        }
    }, [tableId]);

    useEffect(() => {
        const innerUseEffect = async () => {
            await getControlExactOnlines();
        };
        innerUseEffect();
    }, [getControlExactOnlines])

    return (<>
        {controlExactOnlines &&
            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>ControlExactOnline</Form.Label>
                    </Col>
                    <Col lg="12">
                        <SelectBox
                            showClearButton={true}
                            items={controlExactOnlines}
                            valueExpr="id"
                            displayExpr="name"
                            defaultValue={fieldValue.action.controlExactOnlineId}
                            onValueChanged={async (e) => {
                                setFieldValue({ ...fieldValue, action: { ...fieldValue.action, controlExactOnlineId: e.value } });
                                checkChanges();
                            }}
                        /></Col>
                </Row>
            </Form.Group>}
    </>)
}

export default ExactOnline;