import { useGraphContext } from "../graph/context";
import { confirm } from 'devextreme/ui/dialog';
import React, { useCallback, useMemo } from "react";
import appSettings from "../../appsettings";
import msalFetch from "../../api/MsalFetch";
import toast from "react-hot-toast";
import {
  DataGrid,
  Column,
  Grouping,
  Sorting,
  Editing,
  Button as DataGridButton,
  RowDragging,
} from "devextreme-react/data-grid";
import DataSource from 'devextreme/data/data_source';
import CustomStore from "devextreme/data/custom_store"
import { Popup } from 'devextreme-react/popup';

const buildFilter = (dataGridRef) => {
  console.log('dataGridRef', dataGridRef);
  return dataGridRef.current.instance().getCombinedFilter()?.reduce((acc, f) => {
    if (Array.isArray(f)) {
      return [...acc, [f[0], f[1], f[2]]];
    }
    return [...acc, f];
  }, []);
}

const PresetFilter = ({
  dataGridRef,
  overviewId,
  onClose,
  allowUpdate,
  allowDelete,
  setPresetFilterValue,
}) => {
  const { currentUser } = useGraphContext();

  const select = useCallback((id, filter) => {
    setPresetFilterValue(filter == null ? null : JSON.parse(filter));
    onClose();
  }, [setPresetFilterValue, onClose]);

  const onPresetFilterSelectionChanged = useCallback((e) => {
    select(e.row.data.id, e.row.data.filter);
    onClose();
  }, [select, onClose]);

  const onPresetFilterRowClick = useCallback((e) => {
    select(e.data.id, e.data.filter);
    onClose();
  }, [select, onClose]);

  const onPresetFilterInitNewRow = useCallback((e) => {
    const filter = buildFilter(dataGridRef);
    e.data = {
      ...e.data,
      limitedToUser: !currentUser?.admin,
      filter: JSON.stringify(filter)
    };
  }, [currentUser, dataGridRef]);

  const updatePresetFilterOrder = useCallback(async (e) => {
    try {
      const res = await msalFetch(null,
        `${appSettings.api.endpoint}/api/overview/reorder-overview-filter/${e.itemData.id}/${e.itemData.overviewId}/${e.fromIndex}/${e.toIndex}`,
        {
          method: "POST",
          headers: { "Content-type": "application/json" },
        },
      );
      if (res.ok) {
        e.component.getDataSource().reload();
      } else {
        toast.error("Oeps er ging even iets mis...");
      }
    } catch (error) {
      toast.error("Oeps er ging even iets mis...");
      throw error;
    }
  }, []);

  const onPresetFilterUdateFilter = useCallback(async (e) => {
    if (!await confirm("Weet u zeker dat u deze filterinstelling wilt bijwerken met de huidige selectie?", "Filters bijwerken")) {
      return;
    }
    const filter = buildFilter(dataGridRef);
    try {
      const res = await msalFetch(null,
        `${appSettings.api.endpoint}/api/overview/update-overview-filter/${e.row.data.id}`,
        {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify({ filter }),
        }
      );
      if (res.ok) {
        toast.success("De filter is bijgewerkt");
        e.component.getDataSource().reload();
      } else {
        toast.error("Oeps er ging even iets mis...");
      }
    } catch (error) {
      toast.error("Oeps er ging even iets mis...");
      throw error;
    }
  }, [dataGridRef]);

  const loadPresetFilter = useCallback(async (e) => {
    try {
      const response = await msalFetch(null, `${appSettings.api.endpoint}/api/overview/get-overview-filters/${overviewId}/`);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(`[Overview] getOverviewData response was not ok: ${data.message}`);
      }
      return data;
    } catch (err) {
      throw err;
    }
  }, [overviewId]);

  const updatePresetFilter = useCallback(async (id, data) => {
    try {
      const res = await msalFetch(null, `${appSettings.api.endpoint}/api/overview/update-overview-filter/${id}`, {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(data)
      });
      if (res.ok) {
        toast.success(`Filter is bijgewerkt`);
      } else {
        toast.error("Oeps er ging even iets mis...");
      }
    } catch (error) {
      toast.error("Oeps er ging even iets mis...");
      throw error;
    }
  }, []);

  const deletePresetFilter = useCallback(async (id) => {
    try {
      const res = await msalFetch(null, `${appSettings.api.endpoint}/api/overview/delete-overview-filter/${id}`, {
        method: "DELETE",
        headers: { "Content-type": "application/json" }
      });
      if (res.ok) {
        toast.success(`Filter is verwijderd`);
      } else {
        toast.error("Oeps er ging even iets mis...");
      }
    } catch (error) {
      toast.error("Oeps er ging even iets mis...");
      throw error;
    }
  }, []);

  const insertPresetFilter = useCallback(async (data) => {
    try {
      const res = await msalFetch(null, `${appSettings.api.endpoint}/api/overview/create-overview-filter/${overviewId}`, {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(data),
      });
      if (res.ok) {
        toast.success(`Filter is toegevoegd`);
      } else {
        toast.error("Oeps er ging even iets mis...");
      }
    } catch (error) {
      toast.error("Oeps er ging even iets mis...");
      throw error;
    }
  }, [overviewId]);

  const dataSourcePresetFilter = useMemo(() => new DataSource({
    store: new CustomStore({
      key: 'id',
      load: loadPresetFilter,
      update: updatePresetFilter,
      remove: deletePresetFilter,
      insert: insertPresetFilter
    })
  }), [loadPresetFilter, deletePresetFilter, updatePresetFilter, insertPresetFilter]);

  return (
    <Popup
      className="bl-popup"
      title="Filter selecteren"
      animation={false}
      showTitle={true}
      resizeEnabled={true}
      visible={true}
      showCloseButton={true}
      onHiding={() => {
        onClose();
      }}
    >
      <DataGrid
        dataSource={dataSourcePresetFilter}
        showBorders={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        showColumnLines={false}

        width={"auto"}
        wordWrapEnabled={true}
        loadPanel={true}
        className="bl-news"
        onRowClick={onPresetFilterRowClick}
        onInitNewRow={onPresetFilterInitNewRow}
      >
        <RowDragging
          allowReordering={true}
          onReorder={updatePresetFilterOrder}
          showDragIcons={true}
          autoScroll={true} />
        <Editing
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
          mode="row"
          useIcons={true}
        />
        <Grouping
          autoExpandAll={true}
          contextMenuEnabled
          allowCollapsing={true}
        />
        <Sorting mode={"none"} />
        <Column
          caption="Titel"
          dataField="title"
          dataType="string"
          width={200}
        />
        <Column
          key="c_limitedToUserNonAdmin"
          dataField="limitedToUser"
          caption="Type"
          dataType="boolean"
          mandatory={true}
          groupIndex={0}
          defaultValue={true}
          readOnly={true}
          trueText="Mijn filters"
          falseText="Bedrijfsfilters"
        />
        {currentUser?.admin && (
          <Column
            key="c_limitedToUserAdmin"
            dataField="limitedToUser"
            caption="Voor mezelf"
            dataType="boolean"
            mandatory={true}
            defaultValue={true}
            trueText="Voor mezelf"
            falseText="Voor iedereen"
            width={100}
          />
        )}
        {currentUser?.admin && (
          <Column
            caption="Filter"
            dataField="filter"
            dataType="string"
            allowEditing={false}
          />
        )}
        <Column type="buttons" width={"120"} caption={"Acties"} key="filterPresetActionColumn">
          <DataGridButton
            hint="Ga naar filter"
            name="select"
            cssClass="primaryAccent"
            icon="fas fa-arrow-right"
            onClick={onPresetFilterSelectionChanged}
          />
          <DataGridButton
            hint="Update filter met huidige selectie"
            name="select"
            cssClass="primaryAccent"
            icon="fas fa-arrow-up"
            onClick={onPresetFilterUdateFilter}
            visible={(e) => currentUser.admin || e.row.data.userId === currentUser.id}
          />
          {allowUpdate && (
            <DataGridButton
              hint="Bewerk titel"
              name="edit"
              cssClass="primaryAccent"
              icon="fas fa-pen-to-square"
              visible={(e) => currentUser.admin || e.row.data.userId === currentUser.id}
            />
          )}
          {allowDelete && (
            <DataGridButton
              hint="Verwijder filter"
              name="delete"
              icon="trash"
              cssClass="alert"
              visible={(e) => currentUser.admin || e.row.data.userId === currentUser.id}
            />
          )}
        </Column>
      </DataGrid>
    </Popup>
  )
}

export default PresetFilter;
