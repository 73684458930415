import { ExternalEntity } from "../../../../enums/externalEntity.js";
import React, { useState, useEffect, useCallback } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import { CheckBox, NumberBox, SelectBox } from "devextreme-react";
import appSettings from "../../../../appsettings";
import msalFetch from "../../../../api/MsalFetch.js";


const Guid = ({
    fieldValue,
    setFieldValue,
    checkChanges
}) => {
    const [externalEntities, setExternalEntities] = useState();

    const getExternalEntities = useCallback(async () => {
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/dataset/list-external-entity`);
            const data = await response.json();
            setExternalEntities(data);
        } catch (error) {
            throw "Get User table Id error";
        }
    }, []);

    useEffect(() => {
        getExternalEntities();
    }, [getExternalEntities]);
    return <Form.Group>
        <Row>
            <Col lg="3">
                <Form.Label>Externe-entiteits-ID</Form.Label>
            </Col>
            <Col lg="3">
                {externalEntities && <SelectBox
                    showClearButton={true}
                    items={externalEntities}
                    valueExpr="id"
                    displayExpr="name"
                    defaultValue={fieldValue.externalEntityId}
                    onValueChanged={async (e) => {
                        setFieldValue({ ...fieldValue, externalEntityId: e.value });
                        checkChanges();
                    }}
                />}
            </Col>
        </Row>
    </Form.Group>
}

export default Guid;