import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Popup } from 'devextreme-react/popup';
import Button from 'devextreme-react/button';
import { GraphContext, useGraphContext } from "../graph/context";

import "./fallbackComponent.scss";

const FallbackComponent = (e) => {
    const [showModal, setShowModal] = useState(false);
    const currentUser = useGraphContext();
    console.log("currentuser", currentUser);
    return (
        <main className="bl-error-wrapper">
            <section className="bl-card">
                <div className="content-wrapper">
                    <h3>Er is iets mis gegaan.</h3>
                    {currentUser.currentUser.email && <><em>{`Ingelogd als:`}</em><br></br> {`${currentUser.fullname} ${currentUser.currentUser.email}`}<br></br><br></br></>}
                    {currentUser.currentUser.organisation.name && <><em>{`Organisatie:`}</em><br></br>{`${currentUser.currentUser.organisation.name}`}</>}
                    <div className="bl-error-buttons-wrapper">
                        <Link to="/logout">
                            <div className="bl-btn bl-action-btn">
                                <i className="fas fa-tachometer-alt" />
                                <span className="btn-label">Klik om uit te loggen</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>

        </main >
    );
};

export default FallbackComponent;
