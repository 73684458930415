import msalFetch from "../../../api/MsalFetch.js";
import appSettings from "../../../appsettings.js";
import react, { useState, useEffect, useCallback, useRef } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { TextBox, Button } from "devextreme-react";
import toast from "react-hot-toast";
import "../../../../src/components/detailview/Detailview.scss"
import EolSettings from "./EolSettings.js";
import VoipSettings from "./VoipSettings.js";

const Settings = ({
    setChangeInForm,
}) => {
    const onSubmit = useCallback(() => {

    }, []);

    return (
        <Form.Group>
            <Row className="bl-detail-row gx-10">

                <Col md="12" className="bl-col-padding">
                    <section className="bl-detail-column bl-card">
                        <EolSettings />
                    </section>    </Col>

            </Row>


            <Col lg="12" className="bl-col-padding">
                <section className="bl-detail-column bl-card">
                    <VoipSettings />
                </section>
            </Col>



        </Form.Group >)
}

export default Settings;