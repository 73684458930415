import "../notifications/forbidden/forbidden.scss"; // why is this here?
import React, { useCallback, useState, useRef, useMemo, useEffect } from "react";
import appSettings from "../../appsettings";
import msalFetch from "../../api/MsalFetch";
import { useGlobalContext } from "../../context/context";
import ButtonGroup, { Item as ButtonGroupItem } from 'devextreme-react/button-group';
import { useGraphContext } from "../graph/context";
import ContextMenu from 'devextreme-react/context-menu';
import toast from "react-hot-toast";
import QuickEdit from "../modals/EditDetailview/QuickEdit";
import DuplicateDetailview from "../modals/DuplicateDetailview/DUPLICATEDETAILVIEW";
import QuickDetailviewAdd from "../modals/AddDetailview/quickDetailviewAdd/QuickDetailviewAdd";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { useHistory } from "react-router-dom";
import { getFormatter } from '../formats.js';
import DropDownButton from 'devextreme-react/drop-down-button';
import {
    DataGrid,
    Column,
    FilterRow,
    FilterPanel,
    FilterBuilderPopup,
    SearchPanel,
    Scrolling,
    Selection,
    LoadPanel,
    ColumnFixing,
    ColumnChooser,
    Position,
    Grouping,
    GroupPanel,
    GroupItem,
    Summary,
    TotalItem,
    KeyboardNavigation,
    HeaderFilter,
    Toolbar,
    Item,
    Paging,
    Pager,
    Sorting,
    Editing,
    Texts,
    MasterDetail,
    Button as DataGridButton,
    RowDragging,
    StateStoring,
    RemoteOperations,
} from "devextreme-react/data-grid";
import { infoText } from '../../global/paging.js';
import renderColumn from './columns/index';
import "../mastergrid.scss";
import "./List.scss";
import "./parts.scss";
import { Resizable } from "devextreme-react";
import EditHtmlField from '../Popups/EditHtmlField.js';
import { ColumnDisplayValueCalculated } from "../../enums/columnDisplayValue.js";
import { ColumnValidationCurrency } from "../../enums/columnValidation.js";
import PresetFilter from "./PresetFilter";

const buttonDropDownOptions = { width: 230, hint: "Extra opties" };

const List = ({
    overviewId,
    controlRelationId,
    dataSource,
    columns,
    scrollHorizontal,
    columnFunctions,
    tableId,
    isControlRelation,
    expandDetailViewId,
    manualSortColumnId,
    detailViewIdAdd,
    tableItemId,
    excelFilename,
    overviewOrControlRelationName,
    preferredDetailViewId,
    preferredDetailViewIdField,
    preferredDetailViewIdEdit,
    setInlineEditMode,
    inlineEditMode,
    cloneRecord,
    keyExpression,
    externalSelectFunction,
    allowInlineEditing,
    verticalHeight,
    autoRefresh,
    allowCopy,
    allowDelete,
    allowCreate,
    allowRead,
    allowUpdate,
    allowExportExcel,
    duplicateData,
    setDuplicateData,
    gridName,
    overviewFilters,
}) => {
    const [presetFilterValue, setPresetFilterValue] = useState(null);
    const allowedPageSizes = [50, 250, 1000];
    const storeStateValue = `stateStorage`;
    const [popupEditRecordId, setPopupEditRecordId] = useState(null);
    const [showPopupAdd, setShowPopupAdd] = useState(null);
    const [selectedHtmlTableItemId, setSelectedHtmlTableItemId] = useState(null);
    const [storeState, setStoreState] = useState(/* localStorage.storeState !== 'false' */localStorage.getItem(storeStateValue) === 'true');
    const [showPopupSelectPresetFilter, setShowPopupSelectPresetFilter] = useState(false);
    const onClosePresetFilter = useCallback(() => setShowPopupSelectPresetFilter(false), []);
    const { isMobile } = useGlobalContext();
    const dataGridRef = useRef();

    const { push } = useHistory();

    const elementAttr = {
        id: isControlRelation ? "relationGridContainer" : "gridContainer",
    };
    const statePrefix = 'stateStorage_';
    const localStorageKey = `${statePrefix}${isControlRelation ? 'cr' : 'ov'}_${isControlRelation ? controlRelationId : overviewId}`;

    const getCascadingParentId = useCallback((columnId) => {
        const selectedRecord = dataGridRef.current.instance().getSelectedRowsData()[0];
        const column = columns.find(col => col.id === columnId);
        const cascadingParentColumn = columns.find(col => col.id === column.cascadingParentColumnId)
        let cascadingValue = null;
        if (cascadingParentColumn != null) {
            const cascadingParentColumnName = cascadingParentColumn.name.toLowerCase();
            cascadingValue = selectedRecord[cascadingParentColumnName + '_id'];
            return cascadingValue;
        }
    }, [columns]);

    const renderColumns = (columns, datasources, manualSortColumnId, scrollHorizontal, isMobile, refreshGrid) => {
        const columnsWithoutIdOrSortcolumn = columns.filter(column => column.name.toLowerCase() !== 'id' && (manualSortColumnId === null || column.id !== manualSortColumnId));
        const totalWidth = columnsWithoutIdOrSortcolumn.reduce((accumulator, currentValue) => accumulator + currentValue.width, 0);
        return columnsWithoutIdOrSortcolumn.map((column, index) => {
            const {
                id: columnId,
                name,
                alias,
                displayFormat,
                cascadingParentColumnId,
                columnTypeCode,
                options,
                width,
                readonly,
                displayValueId,
                inputColorColumnId,
                mandatory,
                actionId,
                table: { id: tableId },
                columnTypeCodeOfDependColumn,
                columnValidationId,
                sortIndex,
                sortDirection,
                groupIndex,
                extraField1: strExtraField1,
                typeAheadCharacters,
                imageLinkColumnId,
                groupColumnId,
            } = column;
            //to destructuring once figured out
            const actionType = column.action == null ? null : column.action.actionType;
            const oneTime = column.action == null ? null : column.action.oneTime;
            const actionRefresh = column.action == null ? null : column.action.refresh;
            const actionIcon = column.action == null ? null : column.action.icon;
            let columnWidth = 0;
            if (isMobile) {
                columnWidth = `${(width * 50)}px`;
            }
            else {
                if (scrollHorizontal) {
                    columnWidth = `${(width * 100)}px`;
                }
                else {
                    columnWidth = `${(width / totalWidth) * 100}%`;
                }
            }
            return renderColumn({
                columnId,
                columnTypeCode,
                name,
                alias,
                columnWidth,
                displayValueId,
                readonly,
                mandatory,
                options,
                sortIndex,
                sortDirection,
                displayFormat,
                inputColorColumnId,
                //datasources,
                actionId,
                actionType,
                oneTime,
                tableId,
                actionRefresh,
                refreshGrid,
                actionIcon,
                columnTypeCodeOfDependColumn,
                inlineEditMode,
                overviewId,
                columnValidationId,
                groupIndex,
                strExtraField1,
                typeAheadCharacters,
                getCascadingParentId: cascadingParentColumnId === null ? null : getCascadingParentId,
                columnHasImage: imageLinkColumnId !== null,
                columnHasGroup: groupColumnId !== null,
                presetFilterValue,
            }
            );
        });
    };

    const refreshGrid = useCallback(() => {
        // Pass true to repaint updated data rows; false to repaint all data rows.
        dataGridRef.current.instance().refresh(true);
    }, []);

    const renderedColumns = renderColumns(
        columns,
        null, //datasources,
        manualSortColumnId,
        scrollHorizontal,
        isMobile,
        refreshGrid,
    );

    const onCloneClick = useCallback((...args) => {
        cloneRecord(...args);
    }, [cloneRecord]);

    const onEditClick = useCallback((e) => {
        setPopupEditRecordId(e.row.key);
    }, []);

    const redirectToDetailView = useCallback((key, openNewWindow) => {
        if (preferredDetailViewId != null) {
            if (openNewWindow) {
                const win = window.open(`/detail/${preferredDetailViewId}/${key}`, "_blank");
                win.focus();
            } else {
                push(`/detail/${preferredDetailViewId}/${key}`);
            }
        }
    }, [preferredDetailViewId, push]);

    const onRowSelect = useCallback((e, row) => {
        const theRow = e.row || row;
        redirectToDetailView(theRow.data[preferredDetailViewIdField] ?? theRow.key, e.event.ctrlKey)
    }, [redirectToDetailView, preferredDetailViewIdField]);

    const setOpenQuickAddModal = useCallback((bool) => {
        setShowPopupAdd(bool);
    }, []);

    const onCellClick = useCallback((e) => {
        if (e.rowType !== 'data' || ["detailExpand", "buttons", "selection"].includes(e.column.type)) {
            return null;
        }

        let columnName = e.column.dataField.toLowerCase();
        if (columnName.endsWith('_id') && columns.map(col => col.name.toLowerCase()).includes(columnName.slice(0, -3))) {
            columnName = columnName.slice(0, -3);
        }
        let column;
        if (e.column.columnId != null) {
            column = columns.find(col => col.id === e.column.columnId);
        } else {
            //probably redundant, the columns should have their columnIds
            column = columns.find(col => col.name.toLowerCase() === columnName);
        }

        if (column.columnTypeCode === 'HTMLVIEWER' || column.columnTypeCode === 'HTMLEDITOR' || column.columnTypeCode === 'HTMLEDITORADVANCED') {
            if (inlineEditMode || e.value) {
                setSelectedHtmlTableItemId({
                    rowIndex: e.rowIndex,
                    columnIndex: e.columnIndex,
                    alias: column.alias,
                    tableItemId: e.data.id,
                    columnId: e.column.columnId,
                    isHtmlViewer: column.columnTypeCode === 'HTMLVIEWER',
                    isHtmlEditor: column.columnTypeCode === 'HTMLEDITOR',
                    isHtmlEditorAdvanced: column.columnTypeCode === 'HTMLEDITORADVANCED',
                    refreshFunction: refreshGrid,
                    dataGridRef: dataGridRef,
                });
            }
            return null;
        }
        if (inlineEditMode) {
            dataGridRef.current.instance().selectRows([e.key]);
            return null;
        } else {
            if (column.columnTypeCode === 'FILE' || column.columnTypeCode === 'FTP' || column.columnTypeCode === 'ACTION') {
                e.cancel = true;
            } else {
                redirectToDetailView(e.data[preferredDetailViewIdField] ?? e.key, e.event.ctrlKey);
            }
        }
    }, [columns, inlineEditMode, refreshGrid, dataGridRef, preferredDetailViewIdField, redirectToDetailView]);

    // Selectionmode
    const [selectedItemKeys, setSelectedItemKeys] = useState([]);
    const [selectionMode, setSelectionMode] = useState(false);

    const inlineEditModeClick = useCallback(() => {
        setInlineEditMode(!inlineEditMode);
        if (selectionMode) {
            // selection mode is enabled, do not touch the selectedItemKeys
        } else {
            setSelectedItemKeys([]);
        }
    }, [inlineEditMode, selectionMode, setInlineEditMode]);

    const storeStateClick = useCallback(() => {
        const newValue = !storeState;
        const currentInternalState = dataGridRef?.current?.instance().state();
        localStorage.setItem(storeStateValue, newValue);
        if (newValue) {
            localStorage.setItem(localStorageKey, JSON.stringify(currentInternalState));
        } else {
            const itemsToRemove = Object.keys(localStorage).filter(item => item.startsWith(statePrefix));
            for (var c = 0; c < itemsToRemove.length; c++) {
                localStorage.removeItem(itemsToRemove[c]);
            }
            dataGridRef?.current?.instance().state(null);
        }

        setStoreState(newValue);
    }, [storeState, setStoreState, localStorageKey, storeStateValue]);

    const showPopupAddClick = useCallback(() => {
        setShowPopupAdd(true);
    }, []);

    const goToAdd = useCallback((e) => {
        e.event.preventDefault();
        let url = `/detail/${detailViewIdAdd}`;
        if (isControlRelation) {
            url += `?parentTableitemId=${tableItemId}&parentControlRelationId=${controlRelationId}`;
        }
        push(url);
    }, [push, detailViewIdAdd, isControlRelation, tableItemId, controlRelationId]);

    const clearFilterClick = useCallback(() => {
        dataGridRef.current.instance().clearFilter();
    }, []);

    const refreshClick = useCallback(() => {
        refreshGrid();
    }, [refreshGrid]);

    const renderMasterDetail = useCallback((columnData) => {
        return (
            <div className="inline-detailview-container">
                <QuickEdit
                    targetId={columnData.data.key} // @TODO keyExpression
                    setIsEditing={() => {
                        dataGridRef.current.instance().collapseAll(-1);
                    }}
                    refreshGrid={refreshGrid}
                    isRelation={null}
                    controlRelationId={null}
                    isMasterDetail
                    expandDetailviewId={expandDetailViewId}
                />
            </div>
        );
    }, [
        dataGridRef,
        expandDetailViewId,
        refreshGrid,
    ]);

    const exportHandler = useCallback((e) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(overviewOrControlRelationName);

        exportDataGrid({
            component: dataGridRef.current.instance(),
            worksheet: worksheet,
            autoFilterEnabled: true,
            keepColumnWidths: true,
            loadPanel: {
                enabled: true,
                shading: true,
                text: `Excel van ${overviewOrControlRelationName} wordt gemaakt...`,
                shadingColor: "#808080"
            }
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), excelFilename);
            });
        });
        e.cancel = true;
    }, [overviewOrControlRelationName, excelFilename]);


    const exportPDFHandler = useCallback((e) => {
        const doc = new jsPDF();
        const dataGrid = dataGridRef.current.instance();
        exportDataGridToPdf({
            jsPDFDocument: doc,
            indent: 5,
            component: dataGrid,
            loadPanel: {
                enabled: true,
                shading: true,
                text: `PDF van ${overviewOrControlRelationName} wordt gemaakt...`,
                shadingColor: "#808080"
            }
        }).then(() => {
            doc.save(`${overviewOrControlRelationName}.pdf`);
        });
        e.cancel = true;
    }, [overviewOrControlRelationName]);

    const getFormat = useCallback((item) => {
        const column = columns.find((t) => t.id === item.columnId);
        const columnTypeCodeToRender = column.columnTypeCodeOfDependColumn ?? column.columnTypeCode;
        const { columnValidationId, displayValueId } = column;
        let format;

        if (columnTypeCodeToRender === 'EU') {
            format = getFormatter(columnValidationId === ColumnValidationCurrency.Precision2 ? 'EURO2' : 'EURO3');
        } else if (columnTypeCodeToRender === 'DEC') {
            format = getFormatter(column.displayFormat)
        } else if (columnTypeCodeToRender === 'NUM') {
            //format = ',##0'
        } else if (columnTypeCodeToRender === 'PERC') {
            format = getFormatter('PERC');
        } else if (columnTypeCodeToRender === 'CALCULATED') {
            switch (displayValueId) {
                case ColumnDisplayValueCalculated.NUM:
                    {
                        break;
                    }
                case ColumnDisplayValueCalculated.Precision1:
                    {
                        format = getFormatter('1')
                        break;
                    }
                case ColumnDisplayValueCalculated.Precision2:
                    {
                        format = getFormatter('2')
                        break;
                    }
                case ColumnDisplayValueCalculated.Precision3:
                    {
                        format = getFormatter('3');
                        break;
                    }
                case ColumnDisplayValueCalculated.EUPrecison2:
                    {
                        format = getFormatter('EURO2')
                        break;
                    }
                case ColumnDisplayValueCalculated.EUPrecison3:
                    {
                        format = getFormatter('EURO3')
                        break;
                    }
                default:
            }
        } else {
            format = null;
        }
        return format;
    }, [columns]);

    const renderedSummaries = useMemo(() => {
        return columnFunctions?.length > 0 && columns.length > 0 && (
            <Summary recalculateWhileEditing={false}>
                {columnFunctions.map((item, index) => {
                    const column = columns.find((t) => t.id === item.columnId);
                    let columnName = column.name.toLowerCase();
                    const format = getFormat(item);

                    return [
                        <TotalItem
                            key={item.columnId}
                            column={columnName}
                            summaryType={item.function.toLowerCase()}
                            valueFormat={format}
                        />,
                        <GroupItem
                            key={item.columnId}
                            column={columnName}
                            alignByColumn
                            valueFormat={format}
                            summaryType={item.function.toLowerCase()}
                        />
                    ];
                })}
            </Summary>
        );
    }, [columnFunctions, columns, getFormat]);

    const onOptionChanged = useCallback((e) => {
        if (e.name === "filterValue") {
            // we are not allowed to do this
            // this will trigger a rerender of the grid
            // losing the focus
            // setPresetFilterValue(e.value);
        }
    }, []);

    const updateGridOrder = useCallback(async (e) => {
        const sortFieldName = columns.find(c => c.id === manualSortColumnId).name.toLowerCase();
        const sortIndex = e.component.getVisibleRows()[e.toIndex].data[sortFieldName]
        const fromSortFieldValue = e.itemData[sortFieldName];
        const reorderedTableItemId = e.itemData.id;
        const difference = sortIndex - fromSortFieldValue;

        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/table/reorder/${tableId}/${reorderedTableItemId}/${manualSortColumnId}/${fromSortFieldValue}/${fromSortFieldValue + difference}/${overviewId}/${controlRelationId}/${tableItemId}`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" }
                }
            );
            if (res.ok) {

            } else {
                toast.error("Oeps er ging even iets mis...");
            }
        } catch (error) {
            toast.error("Oeps er ging even iets mis...");
            throw error;
        } finally {
            refreshGrid();
        }
    }, [columns, manualSortColumnId, tableId, refreshGrid, overviewId, controlRelationId, tableItemId]);

    const stylObj = { height: isControlRelation ? (verticalHeight ?? 40) + 'vh' : "calc(100vh - 165px)" };

    const hasFileOrFtpColumn = columns.some(({ columnTypeCode }) => ['FILE', 'FTP'].includes(columnTypeCode));

    const onRowPrepared = useCallback((e) => {
        if (!hasFileOrFtpColumn) {
            return;
        }
        if (e.rowType === 'data') {
            e.rowElement.style.height = "115px"; // image column size
        }
    }, [hasFileOrFtpColumn]);

    const handleSaveAndCreateNew = useCallback(() => {
        refreshGrid();
        setShowPopupAdd(false);
        // close the first, wait a milisecond for the Content to unmount, re-mount a fresh Content for fresh data fetching
        setTimeout(() => {
            setShowPopupAdd(true);
        }, 1);
    }, [setShowPopupAdd, refreshGrid]);

    const handleSave = useCallback((recordId) => {
        refreshGrid();
        setShowPopupAdd(false);
        setPopupEditRecordId(recordId);
    }, [refreshGrid]);

    const handleAddNew = useCallback(() => {
        setDuplicateData(null);
        setPopupEditRecordId(null);
        setShowPopupAdd(false);
        // close the first, wait a milisecond for the Content to unmount, re-mount a fresh Content for fresh data fetching
        setTimeout(() => {
            setShowPopupAdd(true);
        }, 1);
    }, [setDuplicateData]);

    const handleUserLeaveContinue = useCallback(() => {
        setDuplicateData(null);
        setPopupEditRecordId(null);
        setShowPopupAdd(false);
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
    }, [refreshGrid, setShowPopupAdd, setDuplicateData]);

    const handleClose = useCallback(() => {
        setDuplicateData(null);
        setPopupEditRecordId(null);
        setShowPopupAdd(false);
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
    }, [refreshGrid, setShowPopupAdd, setDuplicateData]);

    const handleUserLeaveSave = useCallback(() => {
        setDuplicateData(null);
        setPopupEditRecordId(null);
        setShowPopupAdd(false);
        refreshGrid();
    }, [refreshGrid, setShowPopupAdd, setDuplicateData]);

    const handleSaveAndCreateNewEdit = useCallback(() => {
        refreshGrid();
        setDuplicateData(null);
        setPopupEditRecordId(null);
        setTimeout(() => {
            setShowPopupAdd(true);
        }, 1);
    }, [refreshGrid, setDuplicateData]);

    const handleSaveEdit = useCallback(() => {
        refreshGrid();
    }, [refreshGrid]);

    const handleUserLeaveContinueEdit = useCallback(() => {
        setDuplicateData(null);
        setPopupEditRecordId(null);
        refreshGrid();
    }, [refreshGrid, setDuplicateData]);

    const handleCloseEdit = useCallback(() => {
        setDuplicateData(null);
        setPopupEditRecordId(null);
        refreshGrid();
    }, [refreshGrid, setDuplicateData]);

    const handleUserLeaveSaveEdit = useCallback(() => {
        setDuplicateData(null);
        setPopupEditRecordId(false);
        refreshGrid();
    }, [refreshGrid, setDuplicateData]);

    const handleDeleteEdit = useCallback(() => {
        setDuplicateData(null);
        setPopupEditRecordId(false);
        refreshGrid();
    }, [refreshGrid, setDuplicateData]);

    const onShowPopupSelectPresetFilterClick = useCallback((e) => {
        setShowPopupSelectPresetFilter(true);
    }, []);

    const onPresetFiltersSelectionChanged = useCallback((filter) => (e) => {
        // clean filters?
        setPresetFilterValue(filter == null ? null : JSON.parse(filter));
    }, []);

    const { currentUser } = useGraphContext();
    const limited = (overviewFilters || []).filter(({ limitedToUser }) => limitedToUser);
    const notLimited = (overviewFilters || []).filter(({ limitedToUser }) => !limitedToUser);

    const listDropdownButton = [
        {
            html: `<strong>Opties</strong>`,
            disabled: true,
            icon: "fas fa-cogs filtericon",
        },
        {
            text: "Export naar Excel",
            visible: allowExportExcel,
            icon: "fas fa-file-excel filtericon",
            onClick: exportHandler,
        },
        {
            text: "Export naar PDF",
            icon: "fas fa-file-pdf filtericon",
            visible: allowExportExcel,
            hint: "Download PDF bestand",
            onClick: exportPDFHandler
        },
        {
            text: "Beheer filters",
            icon: "fas fa-bookmark filtericon",
            onClick: onShowPopupSelectPresetFilterClick,
            hint: "Toon opgeslagen filters",
        },
        {
            visible: !isControlRelation && !isMobile,
            text: `Filters opslaan staat ${storeState ? "aan" : "uit"}`,
            icon: "fas fa-filter filtericon",
            type: storeState ? "success" : "default",
            stylingMode: 'outline',
            onClick: storeStateClick,

        },
        (notLimited.length > 0 && {
            html: `<strong>${currentUser?.organisation.name} filters</strong>`,
            disabled: true,
            icon: "fas fa-building filtericon",
        }),
        ...(overviewFilters || []).map(({ title, filter, limitedToUser }) => ({
            visible: !limitedToUser,
            text: title,
            // icon: "fas fa-users",
            onClick: onPresetFiltersSelectionChanged(filter),
        })),
        (limited.length > 0 && {
            html: `<strong className="filtergroup">${currentUser?.firstname}'s filters</strong>`,
            icon: 'fas fa-user filtericon',
            disabled: true,
        }),
        ...limited.map(({ title, filter, limitedToUser }) => ({
            visible: true,
            text: title,

            onClick: onPresetFiltersSelectionChanged(filter),
        })),
    ].filter(Boolean);

    const toggleSelectionMode = useCallback(() => {
        setSelectionMode(!selectionMode);
        setSelectedItemKeys([]);
    }, [selectionMode, setSelectionMode]);
    const onSelectionChanged = useCallback((data) => {
        setSelectedItemKeys(data.selectedRowKeys);
    }, []);
    const deleteRecords = useCallback(() => {
        selectedItemKeys.forEach((key) => {
            dataSource.store().remove(key);
        });
        setSelectedItemKeys([]);
        dataSource.reload();
    }, [selectedItemKeys, dataSource]);

    // End of Selectionmode

    // Contextmenu
    const [menuItems, setMenuItems] = useState([]);
    const [menuTarget, setMenuTarget] = useState('#overviewgrid');
    const [expandAll, setExpandAll] = useState([]);
    const [expandButton, setExpandButton] = useState(false);
    const toggleExpandAll = useCallback(() => {
        setExpandAll(!expandAll);
    }, [expandAll, setExpandAll]);
    const handleContextMenuPreparing = (e) => {
        if (e.row && e.row.rowType === 'group') {
            // Set menu target to the row element
            setMenuTarget(e.targetElement);
            // Define menu items
            setMenuItems([
                {
                    html: `<strong>${e.row.data.key} (groep)</strong>`,
                    disabled: true
                },
                (e.row.isExpanded && {
                    text: `${e.row.data.key} inklappen`,
                    icon: 'fas fa-backward-step filtericon',
                    onClick: () => dataGridRef.current.instance().collapseRow(e.row.key)
                }),
                (!e.row.isExpanded && {
                    text: `${e.row.data.key} uitklappen`,
                    icon: 'fas fa-forward-step filtericon',
                    onClick: () => dataGridRef.current.instance().expandRow(e.row.key)
                }),
                {
                    html: `<strong>Alle groepen</strong>`,
                    disabled: true
                },
                {
                    text: 'Alle groepen inklappen',
                    icon: 'fas fa-backward filtericon',
                    onClick: () => dataGridRef.current.instance().collapseAll()
                },
                {
                    text: 'Alle groepen uitklappen',
                    icon: 'fas fa-forward filtericon',
                    onClick: () => dataGridRef.current.instance().expandAll()
                },
            ].filter(Boolean));
        }
        else if (e.row && e.row.rowType === 'data') {
            // Set menu target to the row element
            setMenuTarget(e.targetElement);

            // Define menu items
            setMenuItems([
                {
                    html: `<strong>Item</strong>`,
                    disabled: true
                },
                {
                    text: 'Ga naar item',
                    icon: 'fas fa-arrow-right filtericon',
                    onClick: (event) => onRowSelect(event, e.row),
                },
                (allowCreate && allowCopy && {
                    text: 'Kopieer item',
                    icon: 'fas fa-clone filtericon',
                    onClick: onCloneClick
                }),
                (allowUpdate && {
                    text: 'Edit item',
                    icon: 'fas fa-pen-to-square filtericon',
                    onClick: () => setPopupEditRecordId(e.row.key),
                }),
                (allowDelete && {
                    text: 'Verwijderen',
                    icon: 'fas fa-trash filtericon',
                    onClick: async (contextMenuEvent) => {
                        // @TODO: REMOVE THIS WHEN BUG https://supportcenter.devexpress.com/ticket/details/t1271239/context-menu-onitemclick-getting-fired-twice GETS RESOLVED
                        contextMenuEvent.component.hide();
                        contextMenuEvent.event.stopPropagation();
                        // @TODO: REMOVE THIS WHEN BUG https://supportcenter.devexpress.com/ticket/details/t1271239/context-menu-onitemclick-getting-fired-twice GETS RESOLVED

                        // eslint-disable-next-line no-restricted-globals
                        const result = confirm('Wilt u dit item verwijderen?');
                        if (result) {
                            dataSource.store().remove(e.row.key);
                        }
                    },
                }),

            ].filter(Boolean));
        }
        else {
            setMenuTarget('#overviewgrid');
            setMenuItems([
            ]);
        }
    };

    // End of Contextmenu

    return (
        <>
            <div>
                <Resizable
                    style={stylObj}
                    minHeight={200}
                    handles={"bottom"}
                >

                    <DataGrid
                        renderAsync={false}
                        key={`key_${overviewId ?? controlRelationId}`}
                        title={dataGridRef?.current?.instance().state()}
                        className={""}
                        elementAttr={elementAttr}
                        ref={dataGridRef}
                        dataSource={dataSource}
                        cellHintEnabled={true}
                        showBorders={true}
                        hoverStateEnabled={true}
                        showRowLines={false}
                        showColumnLines={true}
                        id="overviewgrid"
                        rowAlternationEnabled={true}
                        allowColumnReordering={true}
                        noDataText="Geen resultaat"
                        columnAutoWidth={false}
                        columnResizingMode="widget"
                        allowColumnResizing={!isMobile}
                        height={"100%"}
                        onRowPrepared={onRowPrepared}
                        onSelectionChanged={onSelectionChanged}
                        onCellClick={externalSelectFunction ?? onCellClick}
                        width={scrollHorizontal ? "auto" : "100%"}
                        repaintChangesOnly={true}
                        highlightChanges={true}
                        wordWrapEnabled={false}
                        filterSyncEnabled={true}

                        onOptionChanged={onOptionChanged}
                        onContextMenuPreparing={handleContextMenuPreparing}
                    >
                        <RemoteOperations groupPaging={true} />
                        <Grouping autoExpandAll={expandAll} /*{contextMenuEnabled}*/ allowCollapsing={true} />
                        <GroupPanel visible={!isControlRelation && !isMobile} emptyPanelText="Sleep velden hier om te groeperen" />
                        <FilterPanel visible={!isMobile} />
                        {storeState && (
                            <StateStoring
                                enabled={storeState}
                                type="localStorage"
                                savingTimeout={1000}
                                storageKey={localStorageKey} />
                        )}
                        <Sorting mode="multiple" />
                        <Selection mode={selectionMode ? "multiple" : "none"} />
                        <Paging
                            defaultPageSize={50}
                        />
                        <Pager
                            visible={true}
                            allowedPageSizes={allowedPageSizes}
                            displayMode="adaptive"
                            showPageSizeSelector={true}
                            showInfo={true}
                            showNavigationButtons={true}
                            infoText={infoText}
                        />
                        <ColumnChooser enabled={true}>
                            <Position
                                my="right top"
                                at="right bottom"
                                of=".dx-datagrid-column-chooser-button"
                                offset="0 50"
                            />
                        </ColumnChooser>
                        <Toolbar>
                            <Item location="before">
                                <h3 className={`overviewtitle ${isMobile ? "mobile" : ""}`}>{gridName}</h3>
                            </Item>
                            <Item location="center" name="groupPanel" />
                            <Item location="after" name="searchPanel" locateInMenu={isMobile || isControlRelation ? "always" : "never"} />
                            <Item location="after" locateInMenu={isMobile ? "always" : "auto"}>
                                <ButtonGroup
                                    selectionMode="none"
                                    focusStateEnabled={false}
                                    stylingMode={"outlined"}
                                    onItemClick={({ event, itemElement }) => {
                                        event.preventDefault();
                                    }}
                                >
                                    {allowUpdate && allowInlineEditing && (
                                        <ButtonGroupItem
                                            icon="fas fa-pencil"
                                            activeStateEnabled={inlineEditMode}
                                            type={inlineEditMode ? "success" : "default"}
                                            hint={`Bewerken staat ${inlineEditMode ? "aan" : "uit"}`}
                                            onClick={inlineEditModeClick}
                                        />)}
                                    {!isMobile && allowCreate && (
                                        <ButtonGroupItem
                                            icon="fas fa-plus-square"
                                            hint="Snel een nieuw record toevoegen"
                                            onClick={showPopupAddClick}
                                            type="default"
                                        />
                                    )}
                                    {allowCreate && (<ButtonGroupItem
                                        icon="fas fa-plus"
                                        hint="Voeg een nieuw record toe"
                                        onClick={goToAdd}
                                        type="default"
                                    />)}
                                    <ButtonGroupItem
                                        icon="fas fa-eraser"

                                        // this should come from the filtering event
                                        // when filtering starts, make it green.
                                        // without using react... 🤷
                                        type={false === true ? "success" : "default"}
                                        hint="Wis filters"
                                        onClick={clearFilterClick}
                                    />
                                    <ButtonGroupItem
                                        icon="fas fa-sync-alt"
                                        hint={`Vernieuwen data`}
                                        type="default"
                                        onClick={refreshClick}
                                    />
                                    <ButtonGroupItem
                                        icon={expandAll ? "fas fa-compress" : "fas fa-expand"}
                                        visible={expandButton}
                                        type={"default"}
                                        hint={`Groepen uitklappen ${expandAll ? "aan" : "uit"}`}
                                        onClick={toggleExpandAll}
                                    />
                                    <ButtonGroupItem
                                        onClick={toggleSelectionMode}
                                        type={selectionMode ? "success" : "default"}
                                        icon="check"
                                        hint="Selectiemodus wijzigen" />
                                        {selectedItemKeys.length && (
                                        <ButtonGroupItem
                                            onClick={deleteRecords}
                                            icon="trash"
                                            type="danger"
                                            hint="Verwijder selectie" />
                                        )}
                                </ButtonGroup>
                            </Item>
                            <Item>
                                <DropDownButton
                                    text=""
                                    icon="menu"
                                    dropDownOptions={buttonDropDownOptions}
                                    items={listDropdownButton}
                                    type="default"
                                    displayExpr={"text"}
                                    showArrowIcon={false}

                                />
                            </Item>

                        </Toolbar>

                        <Editing
                            mode="cell"
                            allowUpdating={inlineEditMode}
                            allowAdding={true}
                            allowDeleting={!isMobile}
                            selectTextOnEditStart
                            refreshMode={autoRefresh === 1 ? "reshape" : "repaint"}
                            useIcons={true}
                        >
                            <Texts
                                confirmDeleteTitle="Item verwijderen"
                                confirmDeleteMessage="Weet u zeker dat u dit item wilt verwijderen?"
                            />
                        </Editing>
                        <KeyboardNavigation enabled={true} />

                        <FilterRow visible={!isMobile} />
                        <FilterBuilderPopup title="Stel je filter samen" />
                        <HeaderFilter visible={true} search={{ enabled: true }} allowSelectAll={true} />
                        <ColumnFixing enabled={true} />
                        <LoadPanel
                            enabled={true}
                            showPane={false}
                            text="Laden"
                        />
                        <SearchPanel
                            visible={true}
                            highlightCaseSensitive={false}
                            placeholder={`${gridName}`}
                            width={isMobile ? "100" : "250"} />
                        <Scrolling
                            mode="standard"

                            showScrollbar="always"
                        />
                        {!isMobile && (
                            <MasterDetail
                                enabled={expandDetailViewId !== null}
                                component={renderMasterDetail}
                            />
                        )}
                        {manualSortColumnId && allowUpdate && (
                            <RowDragging
                                allowReordering={true}
                                onReorder={updateGridOrder}
                                showDragIcons={true}
                                autoScroll={true} />
                        )}
                        {renderedColumns}
                        {!isMobile && (
                            <Column type="buttons" width={"120"} caption={"Acties"} key='actionColumn'>
                                {!isMobile && <DataGridButton hint="Ga naar pagina" name="select" cssClass="primaryAccent" icon="fas fa-arrow-right" onClick={onRowSelect} />}
                                {allowUpdate && <DataGridButton hint="Bewerk dit record" name="update" cssClass="primaryAccent" icon="fas fa-pen-to-square" onClick={onEditClick} />}
                                {!isMobile && allowCreate && allowCopy && <DataGridButton hint="Dupliceer dit record" name="clone" cssClass="primaryAccent" icon="fas fa-clone" onClick={onCloneClick} />}
                                {allowDelete && <DataGridButton hint="Verwijder dit record" name="delete" icon="trash" cssClass="alert" />}
                            </Column>
                        )}

                        {renderedSummaries}
                    </DataGrid>
                    <ContextMenu
                        dataSource={menuItems}
                        className="filtericon"
                        target={menuTarget}
                        // showEvent="dxcontextmenu" // Triggered on right-click
                        onItemClick={(e) => e.itemData.onClick && e.itemData.onClick()}
                    />
                </Resizable>

            </div >

            {showPopupAdd && (
                <QuickDetailviewAdd
                    targetId={detailViewIdAdd}
                    setOpenQuickAddModal={setOpenQuickAddModal}
                    openQuickAddModal={true}
                    refreshGrid={refreshGrid}
                    controlRelationId={controlRelationId}
                    relationTableItemId={tableItemId} // @
                    handleSaveAndCreateNew={handleSaveAndCreateNew}
                    handleSave={handleSave}
                    handleUserLeaveContinue={handleUserLeaveContinue}
                    handleClose={handleClose}
                    handleUserLeaveSave={handleUserLeaveSave}
                    handleAddNew={handleAddNew}
                />
            )
            }
            {
                duplicateData && (
                    <DuplicateDetailview
                        isRelation={isControlRelation}
                        duplicateData={duplicateData}
                        isOpen
                        handleSave={handleSaveEdit}
                        handleSaveAndCreateNew={handleSaveAndCreateNewEdit}
                        handleUserLeaveContinue={handleUserLeaveContinueEdit}
                        handleClose={handleCloseEdit}
                        handleUserLeaveSave={handleUserLeaveSaveEdit}
                        handleDelete={handleDeleteEdit}
                        handleAddNew={handleAddNew}
                    />
                )
            }
            {
                popupEditRecordId != null && popupEditRecordId !== false && (
                    <QuickEdit
                        targetId={popupEditRecordId} // this is the tableitemId
                        isRelation={false}
                        setIsEditing={setPopupEditRecordId}
                        isEditing={true}
                        currentOverview={/*currentOverview*/ { preferredDetailViewId: preferredDetailViewId }}
                        refreshGrid={refreshGrid}
                        controlRelationId={controlRelationId}
                        overrideDetailViewId={preferredDetailViewIdEdit}
                        handleSave={handleSaveEdit}
                        handleSaveAndCreateNew={handleSaveAndCreateNewEdit}
                        handleUserLeaveContinue={handleUserLeaveContinueEdit}
                        handleClose={handleCloseEdit}
                        handleUserLeaveSave={handleUserLeaveSaveEdit}
                        handleDelete={handleDeleteEdit}
                        handleAddNew={handleAddNew}
                    />
                )
            }
            {
                selectedHtmlTableItemId != null && (
                    <EditHtmlField
                        rowIndex={selectedHtmlTableItemId.rowIndex}
                        columnIndex={selectedHtmlTableItemId.columnIndex}
                        alias={selectedHtmlTableItemId.alias}
                        tableId={tableId}
                        tableItemId={selectedHtmlTableItemId.tableItemId}
                        columnId={selectedHtmlTableItemId.columnId}
                        readOnly={selectedHtmlTableItemId.isHtmlViewer || !inlineEditMode}
                        setSelectedHtmlTableItemId={setSelectedHtmlTableItemId}
                        isHtmlViewer={selectedHtmlTableItemId.isHtmlViewer}
                        isHtmlEditor={selectedHtmlTableItemId.isHtmlEditor}
                        isHtmlEditorAdvanced={selectedHtmlTableItemId.isHtmlEditorAdvanced}
                        refreshFunction={selectedHtmlTableItemId.refreshFunction}
                        dataGridRef={selectedHtmlTableItemId.dataGridRef}
                    />
                )
            }
            {showPopupSelectPresetFilter && (
                <PresetFilter
                    onClose={onClosePresetFilter}
                    overviewId={overviewId}
                    allowUpdate={allowUpdate}
                    allowDelete={allowDelete}
                    dataGridRef={dataGridRef}
                    setPresetFilterValue={setPresetFilterValue}
                />
            )}
        </>
    );
};

export default List;

