import appSettings from "../../../appsettings";
import React, { useState, useEffect } from "react";
import msalFetch from "../../../api/MsalFetch.js";
import Loading from "../../loading/Loading";
import BloomFooter from "../../footer/BloomFooter";
import toast from "react-hot-toast";
import { Container, Col, Row } from "react-bootstrap";
import GridEditEolConnectorColumn from "./GridEditEolConnectorColumn.js";
import GridControlExactOnlineRelation from "./GridControlExactOnlineRelation.js";
import { useParams } from "react-router-dom";

const EolConnectorColumns = (
) => {
    const { eolConnectorId } = useParams();
    console.log("EolConnectorColumns", eolConnectorId);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState();

    useEffect(() => {
        const innerUseEffect = async () => {
            try {
                const res = await msalFetch(null, `${appSettings.api.endpoint}/api/exactonline/read/${eolConnectorId}`);
                const json = await res.json();
                setInfo(`(${json.name} - ${json.entity})`);
            } catch (error) {
                toast.error("Er is iets misgegaan");
                throw error;
            }
        };
        innerUseEffect();
    }, [eolConnectorId])

    return (
        <main className="bl-content">
            <header>
                <h2>ExactOnlineconnectorkolommen {info}</h2>
            </header>
            {loading && <Loading />}
            {!loading && (
                <Container fluid
                    className={"bl-detailview-content"}>
                    <section >
                        <Row className="bl-detail-row">
                            <Col
                                lg={12}
                                className={`bl-col-padding`}
                            >
                                <section className="bl-detail-column bl-card">
                                    <h3 className="bl-set-title" >
                                        Kolommen
                                    </h3>
                                    <section className="propertyWrapper" >
                                        <GridEditEolConnectorColumn
                                            selectedConnectorId={eolConnectorId}
                                        />
                                    </section>
                                </section>
                            </Col>

                        </Row>
                        <Row className="bl-detail-row">
                            <Col
                                lg={12}
                                className={`bl-col-padding`}
                            >
                                <h3 className="bl-set-title" >
                                    Relaties
                                </h3>
                                <section className="bl-detail-column bl-card">
                                    <GridControlExactOnlineRelation
                                        eolConnectorId={eolConnectorId}
                                    />
                                </section>
                            </Col>
                        </Row>
                    </section>
                </Container>
            )
            }

            <BloomFooter />

        </main >)
}

export default EolConnectorColumns;