import { DataGrid } from "devextreme-react";
import appSettings from "../../../appsettings";
import CustomStore from "devextreme/data/custom_store"
import DataSource from 'devextreme/data/data_source';
import {
    Column,
    FilterRow,
    Scrolling,
    Editing,
    Texts,
    Lookup
} from "devextreme-react/data-grid";
import React, { useCallback, useState, useEffect, useRef, useMemo } from "react";
import msalFetch from "../../../api/MsalFetch.js";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

const GridEditEolConnector = ({
    selectedConnectorId,
    setSelectedConnectorId,
}) => {
    const [tables, setTables] = useState(null);
    const { push } = useHistory();
    const refConnectors = useRef();

    useEffect(() => {

        try {
            const innerUseEffect = async () => {
                const response = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/table`);
                const data = await response.json();
                setTables(data);
            };
            innerUseEffect();

        } catch (error) {
            //setLoading(false);
        }
    }, []);

    const getConnectors = useCallback(async () => {
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/exactonline/index-control-exact-online`);
            const data = await response.json();
            //setLoading(false);
            return data;

        } catch (error) {
            //setLoading(false);
        }
    }, []);

    useEffect(() => {
        getConnectors();
    }, [getConnectors]);

    const updateConnector = useCallback(async (id, values) => {
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/exactonline/update/${id}`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(values),
                }
            );
        } catch (error) {
            toast.error("Er is iets misgegaan");
            throw error;
        }
    }, []);

    const insertConnector = useCallback(async (values) => {
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/exactonline/create/`,
                {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(values),
                }
            );
        } catch (error) {
            toast.error("Er is iets misgegaan");
            throw error;
        }
    }, []);

    const deleteConnector = useCallback(async (id) => {

        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/exactonline/delete-control-exact-online/${id}`,
                {
                    method: "DELETE",
                    headers: { "Content-type": "application/json" }
                }
            );
            if (id === selectedConnectorId) {
                setSelectedConnectorId(null);
            }
        } catch (error) {
            toast.error("Er is iets misgegaan");
            throw error;
        }
    }, [selectedConnectorId, setSelectedConnectorId]);

    const dataSourceConnector = useMemo(() => new DataSource({
        store: new CustomStore({
            key: 'id',
            load: getConnectors,
            update: updateConnector,
            remove: deleteConnector,
            insert: insertConnector
        })
    }), [getConnectors, updateConnector, deleteConnector, insertConnector]);

    const handleConnectorRowClick = (rowData) => {
        if (rowData.row.isEditing || rowData.row.isNewRow) {
            return;
        }
        push(`/ExactOnline/columns/${rowData.row.key}`);
    };

    return <DataGrid
        dataSource={dataSourceConnector}
        rowAlternationEnabled={true}
        showColumnLines={false}
        hoverStateEnabled={true}
        showBorders
        showRowLines={true}
        focusedRowEnabled={true}
        className="sorting-disabled"
        keyExpr="id"
        ref={refConnectors}
        onCellClick={handleConnectorRowClick}
        allowUpdating={true}
    >
        <FilterRow visible={true} />
        <Scrolling mode="virtual" />

        <Column
            caption="Naam"
            dataField="name"
            dataType="string"
        />
        <Column
            caption="Tabel"
            dataField="tableId"
            dataType="string"
        >
            <Lookup dataSource={tables} displayExpr="tableName" valueExpr="id" />
        </Column>
        <Column
            caption="Entiteit"
            dataField="entity"
            dataType="string"
        />
        <Column
            caption="Type"
            dataField="type"
            dataType="string"
        >
            <Lookup dataSource={['IN', 'OUT', 'SYNC', 'NONE']} />
        </Column>
        <Column
            caption="Division"
            dataField="division"
            dataType="number"
        />
        <Editing
            mode="row"
            confirmDelete={true}
            allowDeleting={true}
            allowUpdating={true}
            allowAdding={true}
            useIcons={true}
        >
            <Texts
                confirmDeleteTitle="Item verwijderen"
                confirmDeleteMessage="Weet u zeker dat u dit item wilt verwijderen?"
            />
        </Editing>
    </DataGrid>;
}

export default GridEditEolConnector;