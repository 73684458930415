import React, { useRef, useCallback } from 'react';
import DropDownButton from 'devextreme-react/drop-down-button';
import { Template } from 'devextreme-react/core/template';
import { UserMenuSection } from './user-menu-section';
import { useGraphContext } from '../../graph/context';
import userPlaceHolder from "../../../../src/assets/images/userPlaceholder.png"

import './user-panel.scss';

export const UserPanel = ({ menuMode }) => {
  const { profilePic, fullName } = useGraphContext();
  const listRef = useRef(null);

  const dropDownButtonAttributes = {
    class: 'user-button'
  };

  const buttonDropDownOptions = {
    width: '200px'
  };

  const dropDownButtonContentReady = useCallback(({ component }) => {
    component.registerKeyHandler('downArrow', () => {
      listRef.current?.instance().focus();
    });
  }, [listRef]);

  return (
    <div className='user-panel'>
      {menuMode === 'context' && (
        <DropDownButton
          stylingMode='text'
          icon={profilePic ? profilePic : userPlaceHolder}
          showArrowIcon={false}
          elementAttr={dropDownButtonAttributes}
          dropDownOptions={buttonDropDownOptions}
          dropDownContentTemplate='dropDownTemplate'
          onContentReady={dropDownButtonContentReady}
        >
          <Template name='dropDownTemplate'>
            <UserMenuSection listRef={listRef} />
          </Template>
        </DropDownButton>
      )}
      {/* {menuMode === 'list' && (
        <UserMenuSection showAvatar />
      )} */}
    </div>
  );
};

export default UserPanel;
