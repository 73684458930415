import msalFetch from "../../../api/MsalFetch.js";
import appSettings from "../../../appsettings.js";
import react, { useState, useEffect, useCallback, useRef } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { TextBox, Button } from "devextreme-react";
import toast from "react-hot-toast";

const VoipSettings = () => {
    const [voipgridToken, setVoipgridToken] = useState();

    useEffect(() => {
        const innerUseEffect = async () => {
            const data = await msalFetch(null, `${appSettings.api.endpoint}/api/settings/get-all`);
            const json = await data.json();
            setVoipgridToken(json.find(item => item.name === 'VoipgridToken')?.value)
        };
        innerUseEffect();
    }, [])

    const onChange = useCallback((name) => (e) => {
        console.log("onChange", name, e);
        switch (name) {
            case 'VoipgridToken':
                setVoipgridToken(e.value);
                break;
            default:

        }
    }, []);

    const onSubmit = useCallback(async () => {
        const url = `${appSettings.api.endpoint}/api/settings/update-settings`;
        try {
            //debugger;
            const res = await msalFetch(null, url, {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify({
                    VoipgridToken: voipgridToken
                }),
            });
            //const data = await res.json();
            if (res.ok) {
                toast.success('Instellingen zijn opgeslagen');
            }
            else {
                toast.error('Fout opgetreden bij het opslaan van de instellingen');
            }
        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            throw error;
        }
    }, [voipgridToken]);


    return <>
        <h3>VOIP</h3>
        <Form.Group>
            <Row>
                <Col lg="6">
                    VoysToken
                </Col>
                <Col lg="6">
                    <TextBox
                        value={voipgridToken}
                        onValueChanged={onChange('VoipgridToken')}
                        showClearButton={false}
                        valueChangeEvent="input"
                    />
                </Col>
            </Row>
        </Form.Group>
        <Button text='Opslaan' onClick={onSubmit} />
    </>
}
export default VoipSettings;