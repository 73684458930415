import React, { useMemo } from 'react';
import List from 'devextreme-react/list';
import { useHistory } from "react-router-dom";
import { useGraphContext } from '../../graph/context';
import userPlaceholder from "../../../../src/assets/images/userPlaceholder.png"


import './user-menu-section.scss';

export const UserMenuSection = ({ listRef }) => {
    const { push } = useHistory();
    const { currentUser } = useGraphContext();
    const { profilePic, fullname } = useGraphContext();

    const userMenuItems = useMemo(
        () => [
            {
                text: 'Wijzig foto',
                icon: 'user',
                onClick: () => window.open(`https://myaccount.microsoft.com/?ref=MeControl`, "_blank")

            },
            {
                text: 'Uitloggen',
                icon: 'runner',
                onClick: () => push('/logout'),
            }
        ]

        ,
        [push]
    );

    const listElementAttr = {
        class: 'user-info-list'
    };

    return (
        <>
            <div className='user-info'>

                <div className='image-container'>
                    <div
                        style={{
                            backgroundImage: `url(${profilePic ? profilePic : userPlaceholder})`,
                        }}
                        className='user-image'
                    />
                </div>

                <div className='user-name'>{fullname}</div>
            </div>
            <List
                ref={listRef}
                elementAttr={listElementAttr}
                items={userMenuItems}
            />
        </>
    );
};
