import React, { useState, useEffect, useCallback } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import { useGraphContext } from '../../graph/context';
import NewsitemPopup from "../../Popups/NewsItem";
import NavSearch from '../../UserNav/NavSearch';
import UserPanel from './user-panel';
import { ThemeSwitcher } from './theme-switcher';
import { useGlobalContext } from '../../../context/context';
import { Link } from "react-router-dom";
import appSettings from "../../../appsettings";
import msalFetch from "../../../api/MsalFetch.js";

import './header.scss';

export const AppHeader = ({ menuToggleEnabled, title, toggleMenu, className }) => {
    const {
        currentUser,
        nrOfUnreadNewsMessages,
        nrOfUnprocessedNotifications,
        lastHighPriorityNewsItem,
    } = useGraphContext();
    const organisationName = currentUser?.organisation?.name;
    const { isMobile } = useGlobalContext();

    const [themes, setThemes] = useState([]);
    const loadThemes = useCallback(async () => {
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/theme`);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`[Themes] response was not ok: ${data.message}`);
            }
            setThemes(data);
        } catch (error) {
            throw error;
        }
    }, []);

    useEffect(() => {
        loadThemes();
    }, [loadThemes]);

    return (
        <header className={`header-component ${className || ""}`}>
            {organisationName && (
                <Toolbar className='header-toolbar'>
                    <Item visible={menuToggleEnabled} location='before' widget='dxButton' cssClass='menu-button'>
                        <Button icon='menu' stylingMode='text' onClick={toggleMenu} />
                    </Item>
                    <Item
                        location='before'
                        cssClass='header-title'
                        text={organisationName}
                        visible={!organisationName.endsWith("Bloom") && !organisationName.endsWith("Gelders")}
                    />
                    <Item
                        location='before'
                        visible={organisationName.endsWith("Bloom")}>
                        <img src="https://www.werkenmetbloom.nl/media/cache/webp/c9/Logo_Bloom.118x51.webp" height="35" alt="logo" />
                    </Item>
                    <Item
                        location='before'
                        visible={organisationName.endsWith("Gelders")}>
                        <img src="https://www.gvgintranet.nl/gelders2025/gelders_brief_logo.png" height="35" alt="logo" />
                    </Item>

                    <Item location="after" widget="dxAutocomplete" locateInMenu={isMobile ? "always" : "never"}>
                        <NavSearch />
                    </Item>

                    {currentUser?.admin === 1 && nrOfUnprocessedNotifications > 0 && (
                        <Item location="after" locateInMenu={"always"}>
                            <div className="messages">
                                <Link to="/notifications">
                                    <Button icon="belloutline" stylingMode="text" hint="Ga naar meldingen" text="meldingen" />
                                    <div className="dx-badge">
                                        {nrOfUnprocessedNotifications}
                                    </div>
                                </Link>
                            </div>
                        </Item>
                    )}
                    {currentUser?.admin === 1 && (
                        <Item location="after" locateInMenu={"always"}>
                            <div className="messages">
                                <Link to="/actions">
                                    <Button icon="fas fa-bolt" stylingMode="text" hint="Ga naar acties" text="Acties" />
                                </Link>
                            </div>
                        </Item>
                    )}
                    <Item location="after" locateInMenu={"always"}>
                        <div className="messages">
                            <Link to="/news">
                                <Button icon="fas fa-newspaper" stylingMode="text" hint="Ga naar nieuwsberichten" text="Nieuws" />
                                {nrOfUnreadNewsMessages > 0 &&
                                    <div className="dx-badge">
                                        {nrOfUnreadNewsMessages}
                                    </div>
                                }
                            </Link>
                        </div>
                    </Item>
                    <Item location='after' locateInMenu='auto' menuItemTemplate='userPanelTemplate'>
                        <UserPanel menuMode='context' />
                    </Item>
                    {/* <Template name='userPanelTemplate'>
                        <UserPanel menuMode='list' />
                    </Template> */}

                    <Item location='after' locateInMenu='always'>
                        <ThemeSwitcher themes={themes} />
                    </Item>
                    {currentUser?.admin === 1 && nrOfUnprocessedNotifications > 0 && (
                        <Item location="after" locateInMenu={"never"}>

                            <div className="dx-badge">
                                {nrOfUnprocessedNotifications}

                            </div>
                        </Item>
                    )}
                </Toolbar>
            )
            }

            {
                lastHighPriorityNewsItem != null && (
                    <NewsitemPopup
                        selectedTitle={lastHighPriorityNewsItem?.title}
                        selectedText={lastHighPriorityNewsItem?.text}
                        newsItemId={lastHighPriorityNewsItem?.id}
                        isHighPriorityItem={true}
                        showCloseButton={false}
                        itemIsRead={false}
                    />
                )
            }
        </header >
    );
};

export default AppHeader;
