import { useCallback } from "react";
import moment from "moment";

const convertBackendDate = (value) => {
  if (!value) {
    return null;
  }
  // format: "22-12-2022 00:00:00"
  // parse it as utc, so the hours will not be shifted by moment
  //return moment.utc(value, "DD-MM-YYYY hh:mm:ss").format('YYYY-MM-DDTHH:mm:ss');
  return moment.utc(value, "M/D/YYYY h:mm A").format('YYYY-MM-DDTHH:mm:ss');
}

const convertBackendBoolean = (value) => {
  // backend outputs booleans toString() 💩 -- Laurens Kling, 2022
  if (value === 'True') {
    return true;
  }
  if (value === 'False') {
    return false;
  }
  return null;
}

// sometimes, the backend gives us bad values. Transform them.
// why does the backend do that? It does a hard value.toString() in C#
// that means that dates, numbers, etc. all get stringified by locale. Bad server, bad. -- Laurens Kling, 2022
export const convertBackendValueForColumn = ({ columnTypeCode, value }) => {
  if (columnTypeCode === 'D') {
    return convertBackendDate(value);
  }
  if (columnTypeCode === 'DT') {
    return convertBackendDate(value);
  }
  if (columnTypeCode === 'ACTION') {
    return convertBackendDate(value);
  }
  if (columnTypeCode === 'AU') {
    return convertBackendBoolean(value);
  }
  if (columnTypeCode === 'JN') {
    return convertBackendBoolean(value);
  }

  return value;
}

export const useDefaultValueForColumn = (currentUser) => {
    return useCallback((defaultValue, columnTypeCode) => {
      // fix the poopy True / False defaultValue
      if (columnTypeCode === 'AU') {
        return convertBackendBoolean(defaultValue);
      }
      if (columnTypeCode === 'JN') {
        return convertBackendBoolean(defaultValue);
      }
      // populate the currentUser
      if (defaultValue === 'CURRENTUSER') {
          return String(currentUser?.id);
      }
      // do magic with default dates
      if (defaultValue.includes('GETDATELOCAL()')) {
          const today = new Date();
          if (defaultValue.endsWith('D')) {
              // for example : GETDATELOCAL()+1D
              const howManyDays = defaultValue.match(/\d+/)[0];
              const offsettedDate = new Date();
              const plusHowManyDays = today.getDate() + parseInt(howManyDays, 10);
              offsettedDate.setDate(plusHowManyDays);
              return offsettedDate.toISOString();
          }
          if (defaultValue.endsWith('H')) {
              // for example : GETDATELOCAL()+1H
              const howManyHours = defaultValue.match(/\d+/)[0];
              const offsettedDate = new Date();
              const plusHowManyHours = today.getHours() + parseInt(howManyHours, 10);
              offsettedDate.setHours(plusHowManyHours);
              return offsettedDate.toISOString();
          }
          if (defaultValue.endsWith('M')) {
              // for example : GETDATELOCAL()+1M
              const howManyMinutes = defaultValue.match(/\d+/)[0];
              const offsettedDate = new Date();
              const plusHowManyMinutes = today.getMinutes() + parseInt(howManyMinutes, 10);
              offsettedDate.setMinutes(plusHowManyMinutes);
              return offsettedDate.toISOString();
          }
          return moment.parseZone(today).utc(true).format('YYYY-MM-DDTHH:mm:ss');
      }
      // otherwise we'll just try the defaultValue, yikes.
      return defaultValue;
    }, [currentUser]);
}
