import { formatNumber as devExtremeFormatNumber, parseNumber } from "devextreme/localization";

export const fromBackendValue = (str) => Number(String(str).replace(',', '.'));
export const toBackendValue = str => (str === null || str === undefined) ? null : String(str).replace('.', ',');

export const formatNumber = (str, ...args) => {
    const num = fromBackendValue(str);
    return devExtremeFormatNumber(num, ...args);
}

export const getFormat = (displayFormat) => {
    switch (displayFormat) {
        case "EURO":
            {
                return {
                    formatter: (val) => {
                        return formatNumber(val, '€ #0.00');
                    },
                    parser: (val) => {
                        return parseNumber(val,
                            {
                                type: 'fixedPoint',
                                precision: 2,
                            });
                    },
                    format: {
                        style: "currency",
                        currency: "EUR",
                        currencySign: "standard",
                        minimumFractionDigits: 2,
                    }
                };
            }
        case "EURO3":
            {
                return {
                    formatter: (val) => {
                        return formatNumber(val, '€ #0.000');
                    },
                    parser: (val) => {
                        return parseNumber(val,
                            {
                                type: 'fixedPoint',
                                precision: 3,
                            });
                    },
                    format: {
                        style: "currency",
                        currency: "EUR",
                        currencySign: "standard",
                        minimumFractionDigits: 3,
                    }
                };
            }
        case "PERC":
            {
                return {
                    formatter: (val) => {
                        return formatNumber(val, '#0.0 %');
                    },
                    parser: (val) => {
                        return parseNumber(val,
                            {
                                type: 'percent',
                                precision: 1,
                            });
                    }
                };
            }
        case "1":
            {
                return {
                    formatter: (val) => {
                        return formatNumber(val, '#0.0');
                    },
                    parser: (val) => {
                        return parseNumber(val,
                            {
                                type: 'fixedPoint',
                                precision: 1,
                            });
                    }
                };
            }
        case "2":
            {
                return {
                    formatter: (val) => {
                        return ('' + formatNumber(val, '#0.00'));
                    },
                    parser: (val) => {
                        return parseNumber(val,
                            {
                                type: 'fixedPoint',
                                precision: 2,
                            });

                    }
                };
            }
        case "3":
            {
                return {
                    formatter: (val) => {
                        return formatNumber(val, '#0.000');
                    },
                    parser: (val) => {
                        return parseNumber(val,
                            {
                                type: 'fixedPoint',
                                precision: 3,
                            });
                    }
                };
            }
        case "0":
        default:
            {
                return {
                    formatter: (val) => {
                        return formatNumber(val, '#0');
                    },
                    parser: (val) => {
                        return parseNumber(val, {
                            type: 'fixedPoint',
                            precision: 0,
                        });
                    }
                };
            }
    }
}

export const getFormatter = (displayFormat) => {
    switch (displayFormat) {
        case "EURO2":
            {
                return {
                    style: "currency",
                    currency: "EUR",
                    currencySign: "standard",
                    minimumFractionDigits: 2,

                }
            }
        case "EURO3":
            {
                return {
                    style: "currency",
                    currency: "EUR",
                    currencySign: "standard",
                    minimumFractionDigits: 3,
                }
            }
        case "EURO0":
            {
                return {
                    style: "currency",
                    currency: "EUR",
                    currencySign: "standard",
                    maximumFractionDigits: 0,
                }
            }
        case "PERC":
            {
                return {
                    style: "percent",
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1
                };
            }
        case "1":
            {
                return ',##0.0';
            }
        case "2":
            {
                return ',##0.00';
            }
        case "3":
            {
                return ',##0.000';
            }
        case "0":
            return null;
        default:
            {
                return ',##0'
            }
    }
}

export const stepIncrement = (displayFormat) => {
    switch (displayFormat) {
        case "EURO":
            return 0.01;
        case "EURO3":
            return 0.001;
        case "PERC":
            return 0.001;
        case "1":
            return 0.1;
        case "2":
            return 0.01;
        case "3":
            return 0.001;
        case "0":
        default:
            return 1;

    }
};

export const formatDateTime = (dt) => {
    const isValidDate = dt instanceof Date && !isNaN(dt);
    if (isValidDate) {
        return Intl.DateTimeFormat('nl-NL', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        }).format(dt)
    } else {
        return 'INVALID';
    }
};

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : 0
    const sizes = ['KB', 'MB', 'GB', 'TB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    const ant = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
    const ernatie = formatNumber('' + ant);
    return `${ernatie} ${sizes[i]}`
}
